import { AntDesign } from '@expo/vector-icons';
import { Box, Icon, Pressable, View, useTheme } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { CreatePassengerInput } from 'src/graphql/ticketing_api/mutations/create-reservation';

export interface PassengerItemProps {
  passenger: CreatePassengerInput;
  index: number;
  onPassengerEdited: (passenger: CreatePassengerInput, index: number) => void;
}

export const PassengerItem: FC<PassengerItemProps> = ({ passenger, onPassengerEdited, index }) => {
  const { colors } = useTheme();

  return (
    <Box
      shadow="1"
      bg={colors.white}
      flexDirection="row"
      padding={5}
      justifyContent="space-between"
      borderRadius={4}
      alignItems="center"
    >
      <View flexDirection="row" alignItems="center">
        <Icon as={AntDesign} name="user" size={6} color={colors.muted[300]} />
        <View marginLeft={4}>
          <SubSubHeader text={`${passenger.title}. ${passenger.fullName}`} />
          <BodyText text={passenger.isPrimaryPassenger ? 'Lead Passenger' : `${passenger.type} Passenger`} />
        </View>
      </View>
      <Pressable onPress={() => onPassengerEdited(passenger, index)}>
        <Icon as={AntDesign} name="edit" size={6} color={colors.primary[300]} />
      </Pressable>
    </Box>
  );
};

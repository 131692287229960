import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export const captureException = (error: any) => {
  if (Platform.OS == 'web') {
    return Sentry.Browser.captureException(error);
  } else {
    return Sentry.Native.captureException(error);
  }
};

export const captureMessage = (message: any) => {
  if (Platform.OS == 'web') {
    return Sentry.Browser.captureMessage(message);
  } else {
    return Sentry.Native.captureMessage(message);
  }
};

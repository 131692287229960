import { MaterialCommunityIcons, Entypo, AntDesign, Ionicons } from '@expo/vector-icons';
import { Avatar, HStack, VStack, Box, View, FlatList, Badge, useTheme, Button, ScrollView, Pressable, Divider } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { LoadingState } from 'src/components/loading-state/LoadingState';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { COLORS, PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { capitalize, getInitials } from 'src/utils/string';
import moment from 'moment';
import { StackActions, useLinkProps } from '@react-navigation/native';
import { CHECK_IN_SCREEN_NAME, CHECK_IN_SEAT_SCREEN_NAME } from 'src/navigation/constants';

export interface PassengerItemProps {
  passengerId: string;
  fullName: string;
  type: string;
  isCheckinAvailable: boolean;
  seatNumber?: string;
}

export const OriginDestinationIllustration = ({ color, size }: { color: string; size?: number }) => {
  return (
    <HStack space={2} alignItems="center">
      <Entypo name="dots-three-horizontal" size={size} color={color} />
      <MaterialCommunityIcons name="bus" size={size} color={color} />
      <Entypo name="dots-three-horizontal" size={size} color={color} />
    </HStack>
  );
};

const PassengerItem = ({ fullName, type, isCheckinAvailable, passengerId, seatNumber }: PassengerItemProps) => {
  const { onPress: goToCheckInSeat } = useLinkProps({
    to: { screen: CHECK_IN_SEAT_SCREEN_NAME, params: { passengerNo: passengerId, passengerName: fullName } },
  });

  return (
    <Pressable onPress={goToCheckInSeat} disabled={!isCheckinAvailable || !!seatNumber}>
      {({ isHovered, isPressed, isFocused }) => (
        <Box padding={4} backgroundColor={isFocused || isHovered || isPressed ? 'gray.100' : undefined}>
          <HStack space={[2, 3]} alignItems="center" justifyContent="space-between">
            <HStack space={[2, 3]} alignItems="center">
              <Avatar size="32px">{getInitials(fullName)}</Avatar>
              <VStack>
                <BodyText text={capitalize(fullName)} />
                {seatNumber && <SmallLabel text={`Seat: ${seatNumber}`} />}
              </VStack>
            </HStack>
            <HStack alignItems={'center'} space={1}>
              <Badge size={'sm'} colorScheme="info" variant="subtle">
                {type}
              </Badge>
              {seatNumber ? (
                <MaterialCommunityIcons name={'check'} size={24} color={'green'} />
              ) : (
                isCheckinAvailable && <Entypo name="chevron-small-right" size={24} />
              )}
            </HStack>
          </HStack>
        </Box>
      )}
    </Pressable>
  );
};

export const CheckinInformation = () => {
  const { booking, resetBooking } = useBooking();
  const { colors } = useTheme();
  const { onPress: goToBookingAccess } = useLinkProps({
    to: { screen: CHECK_IN_SCREEN_NAME },
    action: StackActions.replace(CHECK_IN_SCREEN_NAME),
  });

  const closeBooking = () => {
    resetBooking();
    goToBookingAccess();
  };

  if (!booking?.getReservationBySurname) return <LoadingState />;

  const isCheckingAvailable = booking.getReservationBySurname.trip.isCheckinAvailable;

  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      <ScrollView flex={1} contentContainerStyle={{ justifyContent: 'space-between', flex: 1 }} bounces={false}>
        <View>
          <SubSubHeader text="Reference" />
          <SmallLabel text={`# ${booking.getReservationBySurname.id}`} />
          <Box borderColor={COLORS.grey} borderWidth={2} padding={4} borderRadius={2} marginTop={2}>
            <HStack justifyContent="space-evenly" alignItems="center">
              <VStack alignItems="center">
                <SubSubHeader text={booking.getReservationBySurname.schedule.route.branch.name} />
                <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.branch.address.state})`} />
              </VStack>
              <OriginDestinationIllustration color={colors.primary[PRIMARY_COLOR_NUMBER]} size={20} />
              <VStack alignItems="center">
                <SubSubHeader text={booking.getReservationBySurname.schedule.route.destination.name.toUpperCase()} />
                <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.destination.state ?? ''})`} />
              </VStack>
            </HStack>

            <HStack marginTop={5} justifyContent={'space-between'}>
              <VStack space={1}>
                <HStack space={2}>
                  <MaterialCommunityIcons name="bus" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('DD MMM, YYYY')} />
                </HStack>
                <HStack space={2}>
                  <MaterialCommunityIcons name="clock-time-five-outline" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('HH:SS A')} />
                </HStack>
              </VStack>
              <VStack alignItems="center" space={2}>
                <Ionicons
                  name={isCheckingAvailable ? 'checkmark-circle-sharp' : 'warning'}
                  size={24}
                  color={isCheckingAvailable ? colors.success[300] : colors.warning[300]}
                />
                <BodyText text={isCheckingAvailable ? 'Checkin Available' : 'Checkin Unavailable'} />
              </VStack>
            </HStack>
          </Box>

          <View marginTop={8}>
            <SubSubHeader text="Passengers" />
            <SmallLabel text={isCheckingAvailable ? 'Select passenger to check-in' : 'Passengers for checkin'} />
            <Box borderColor={COLORS.grey} borderWidth={2} borderRadius={2} marginTop={2}>
              <FlatList
                scrollEnabled={false}
                data={booking.getReservationBySurname.tickets}
                ItemSeparatorComponent={() => <Divider backgroundColor={'gray.100'} margin={1} />}
                renderItem={({ item }) => (
                  <PassengerItem
                    fullName={item.passenger.fullName}
                    type={item.passenger.type}
                    isCheckinAvailable={isCheckingAvailable}
                    passengerId={item.passenger.id}
                    seatNumber={item.passenger.seat?.number}
                  />
                )}
                keyExtractor={item => item.passenger.id}
              />
            </Box>
          </View>
        </View>
        <Button onPress={closeBooking} startIcon={<AntDesign name="close" />} marginBottom={2} variant="ghost" colorScheme="secondary">
          Close
        </Button>
      </ScrollView>
    </ContentContainer>
  );
};

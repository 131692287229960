export const colorsOverride = {
  main: '#273c75',
  primary: {
    50: '#5d80e0',
    100: '#4369d3',
    200: '#3458b9',
    300: '#314c94',
    400: '#273c75',
    500: '#263561',
    600: '#222e4d',
    700: '#1e253a',
    800: '#181c28',
    900: '#101218',
  },
  danger: {
    50: '#fa7c7c',
    100: '#f35b5b',
    200: '#e93c3c',
    300: '#dd2121',
    400: '#b91c1c',
    500: '#a01f1f',
    600: '#872020',
    700: '#702020',
    800: '#5a1e1e',
    900: '#451b1b',
  },
  error: {
    50: '#fa7c7c',
    100: '#f35b5b',
    200: '#e93c3c',
    300: '#dd2121',
    400: '#b91c1c',
    500: '#a01f1f',
    600: '#872020',
    700: '#702020',
    800: '#5a1e1e',
    900: '#451b1b',
  },
  success: {
    50: '#3ef581',
    100: '#20ec6a',
    200: '#19ca5a',
    300: '#1aa14c',
    400: '#15803d',
    500: '#156733',
    600: '#14512a',
    700: '#123c21',
    800: '#0e2817',
    900: '#09150d',
  },
  warning: {
    50: '#ff966c',
    100: '#ff7944',
    200: '#f86023',
    300: '#e54b0f',
    400: '#c2410c',
    500: '#a43a10',
    600: '#8a3513',
    700: '#712e14',
    800: '#5a2814',
    900: '#442012',
  },
  dark: {
    50: '#735050',
    100: '#574444',
    200: '#3d3636',
    300: '#262626',
    400: '#111111',
    500: '#060606',
    600: '#-5-6-6',
    700: '#-e-15-15',
    800: '#-16-24-24',
    900: '#-1d-35-35',
  },
};

import Constants from 'expo-constants';
import { Platform } from 'react-native';

export enum EnvkeysEnum {
  ENV = 'ENV',
  AUTH_TOKEN = 'AUTH_TOKEN',
  FLUTTERWAVE_KEY = 'FLUTTERWAVE_KEY',
  PAYSTACK_KEY = 'PAYSTACK_KEY',
  PAYSTACK_SUBACCOUNT_CODE = 'PAYSTACK_SUBACCOUNT_CODE',
  FLUTTERWAVE_SUBACCOUNT_CODE = 'FLUTTERWAVE_SUBACCOUNT_CODE',
  GATEWAY_URL = 'GATEWAY_URL',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}

export const getEnvVariable = (key: EnvkeysEnum): string | null => {
  if (Constants.manifest2?.extra?.expoClient?.extra) {
    return Constants.manifest2?.extra?.expoClient?.extra[key] ?? null;
  }
  if (Constants.manifest?.extra) {
    return Constants.manifest?.extra[key] ?? null;
  }
  return null;
};

export const isWeb = (): boolean => {
  return Platform.OS === 'web';
};

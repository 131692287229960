import { useEffect, useState } from 'react';
import * as Sentry from 'sentry-expo';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';

import { ErrorBoundary } from 'src/components/error-boundary/ErrorBoundary';
import { createApolloClient } from 'src/graphql/client';
import { LoadingState } from 'src/components/loading-state/LoadingState';
import { Router } from 'src/navigation/Router';
import { DesignProvider } from 'src/providers/design-provider/DesignProvider';
import { AuthProvider } from 'src/providers/auth-provider/AuthProvider';
import { EnvkeysEnum, getEnvVariable } from 'src/utils/environment.utils';
import { captureMessage } from 'src/utils/sentry';

Sentry.init({
  dsn: 'https://38f127b5af9466278ffc05b31baed0b9@o269765.ingest.sentry.io/4506076397568000',
  tracesSampleRate: 1.0,
});

const App = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const initClient = async () => {
      const client = await createApolloClient();
      setClient(client);
    };
    initClient();
  }, [setClient]);

  if (!client) {
    return <LoadingState />;
  }

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <AuthProvider>
          <DesignProvider>
            <Router />
          </DesignProvider>
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;

import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getSecure, removeSecure, saveSecure } from './secure-store';

export const saveItem = async (key: string, value: any, secure = false) => {
  if (Platform.OS !== 'web' && secure) {
    return saveSecure(key, value);
  }
  const jsonValue = JSON.stringify(value);
  return await AsyncStorage.setItem(key, jsonValue);
};

export const getItem = async <T = string>(key: string, secure = false): Promise<T | null> => {
  if (Platform.OS !== 'web' && secure) {
    return getSecure(key);
  }
  const data = await AsyncStorage.getItem(key);
  if (data) return JSON.parse(data) as T;

  return null;
};

export const removeItem = async (key: string) => {
  if (Platform.OS !== 'web') {
    return removeSecure(key);
  }
  await AsyncStorage.removeItem(key);
};

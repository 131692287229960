import * as SecureStore from 'expo-secure-store';

export const saveSecure = async (key: string, value: any) => {
  const jsonValue = JSON.stringify(value);
  return await SecureStore.setItemAsync(key, jsonValue);
};

export const getSecure = async <T = string>(key: string): Promise<T | null> => {
  const data = await SecureStore.getItemAsync(key);
  if (data) JSON.parse(data) as T;

  return null;
};

export const removeSecure = async (key: string): Promise<void> => {
  await SecureStore.deleteItemAsync(key);
};

import * as Linking from 'expo-linking';
import {
  HOME_SCREEN_NAME,
  ONBOARDING_SCREEN_NAME,
  GUEST_SCREEN_NAME,
  BOOK_SEAT_SCREEN_NAME,
  RESERVATION_STACK_NAME,
  PASSENGER_INFORMATION_SCREEN_NAME,
  PAYMENT_CONFIRMATION_SCREEN_NAME,
  AVAILABLITY_SCREEN_NAME,
  INBOUND_SCREEN_NAME,
  RESERVATION_CONFIRMED_SCREEN_NAME,
  LANDING_SCREEN_NAME,
  SELECT_SEAT_NAME,
  CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME,
  BOOKING_STACK_NAME,
  BOOKING_INFORMATION,
  BOOKING_ACCESS,
  MANAGE_BOOKING_STACK_NAME,
  CHECK_IN_STACK_NAME,
} from 'src/navigation/constants';

const urlPrefix = Linking.createURL('/');

export const linkingConfig = {
  /**
   *  The "nautic-client" part of the urls must be defined in app.json
   *  under the "scheme" property.
   *  // TODO this will most likely need to be an environment variable.
   *  */
  prefixes: [urlPrefix, 'http://nautic-client.com', 'nautic-client://'],
  /**
   * The name of the property needs to exactly match the value passed
   * to the "name" property of the corresponding Stack.Screen.
   *
   * In this case, name={HOME_SCREEN_NAME} and HOME_SCREEN_NAME is defined in src/constants/navigation.js
   *
   * By default, linking will use the screen's name as a path.
   * So for example, if our HOME_SCREEN had a value of 'HomeScreen'
   * the configuration below will ignore the "Home: 'home'" part
   * and add a path for '/HomeScreen' instead.
   *
   * It is important to be aware of this connection, in order to know what
   * behaviour to expect.
   */
  config: {
    screens: {
      [LANDING_SCREEN_NAME]: '',
      [HOME_SCREEN_NAME]: 'home',
      [ONBOARDING_SCREEN_NAME]: 'onboarding',
      [GUEST_SCREEN_NAME]: 'guest',
      [RESERVATION_STACK_NAME]: {
        screens: {
          [BOOK_SEAT_SCREEN_NAME]: 'book',
          [AVAILABLITY_SCREEN_NAME]: 'availability',
          [INBOUND_SCREEN_NAME]: 'select-inbound',
          [PASSENGER_INFORMATION_SCREEN_NAME]: 'passengers',
          [PAYMENT_CONFIRMATION_SCREEN_NAME]: 'payment-confirmation',
          [RESERVATION_CONFIRMED_SCREEN_NAME]: 'reservation-confirmed',
          [CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME]: 'passenger-info',
          [SELECT_SEAT_NAME]: 'select-seat',
        },
      },
      [BOOKING_STACK_NAME]: {
        screens: {
          [BOOKING_ACCESS]: 'booking',
          [BOOKING_INFORMATION]: 'booking-information',
        },
      },
      [MANAGE_BOOKING_STACK_NAME]: 'manage-booking',
      [CHECK_IN_STACK_NAME]: 'check-in',
    },
  },
};

import { ScrollView, useTheme } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { Greetings } from './components/Greetings';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { SearchCard } from './components/SearchCard';
import { UpcomingTrips } from './components/UpcomingTrips';

const HomeScreenPage = () => {
  const { colors } = useTheme();
  return (
    <ContentContainer
      contentFullWidth
      statusBarColor={colors.primary[PRIMARY_COLOR_NUMBER]}
      statusBarStyle="light-content"
      backgroundColor={colors.primary[PRIMARY_COLOR_NUMBER]}
    >
      <ScrollView backgroundColor={colors.white} showsVerticalScrollIndicator={false} alwaysBounceVertical={false}>
        <Greetings />
        <SearchCard />
        <UpcomingTrips />
      </ScrollView>
    </ContentContainer>
  );
};

export default HomeScreenPage;

import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { StorageKeysEnum } from 'src/constants/storage';

import { captureException } from 'src/utils/sentry';
import { getGraphqlUrl } from 'src/graphql/graphql-utils';
import { getItem } from 'src/utils/storage/store';
import { EnvkeysEnum, getEnvVariable } from 'src/utils/environment.utils';

export const onErrorLink = onError(errors => {
  const { graphQLErrors, networkError } = errors;

  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      captureException(error.extensions);
    });
  } else if (networkError) {
    captureException(networkError.message);
  }
});

export const createApolloClient = async () => {
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({
    uri: getGraphqlUrl(),
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = (await getItem(StorageKeysEnum.AUTH_TOKEN)) ?? getEnvVariable(EnvkeysEnum.AUTH_TOKEN);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    cache,
    link: authLink.concat(onErrorLink).concat(httpLink),
  });
};

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    indicator: {
        height: 7,
        width: 7,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#A3A3A3',
        marginTop: 18,
        marginHorizontal: 3,
        borderRadius: 2
    },
})

export default styles
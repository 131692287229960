import { Ionicons } from '@expo/vector-icons';
import { FormControl, HStack, useTheme } from 'native-base';
import RNPhoneInput, { PhoneInputProps } from 'react-native-phone-number-input';
import { Country } from 'react-native-country-picker-modal';
import { COLORS, FONTS, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';
import { BodyText } from '../typography/BodyText';
import { useEffect, useState } from 'react';

export class PhoneInput extends RNPhoneInput {}

export interface PhoneNumberInputProps extends PhoneInputProps {
  ref: React.RefObject<PhoneInput> | null;
  isRequired?: boolean;
  label?: string;
  errorText?: string;
  successText?: string;
  infoText?: string;
}

export const PhoneNumberInput = ({
  containerStyle,
  textContainerStyle,
  codeTextStyle,
  textInputStyle,
  isRequired,
  label,
  infoText,
  errorText,
  successText,
  countryPickerProps,
  ...props
}: PhoneNumberInputProps) => {
  const { colors } = useTheme();
  const [value, setValue] = useState<string | undefined>();
  const [currentCountryCode, setCurrentCountryCode] = useState<Country['cca2']>(props.defaultCode as Country['cca2']);

  const onChangeCountry = (country: Country) => {
    setCurrentCountryCode(country.cca2);
    if (props.onChangeCountry) {
      props.onChangeCountry(country);
    }
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue, props.value]);

  return (
    <FormControl isRequired={isRequired}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      <RNPhoneInput
        {...props}
        value={value}
        defaultValue={value}
        onChangeCountry={onChangeCountry}
        countryPickerProps={[{ withAlphaFilter: true }, countryPickerProps]}
        // we need to set the value in the text input props as this this seems to be a bug
        // where the value doesn't get updated when using textInputProps
        textInputProps={currentCountryCode === 'NG' ? { maxLength: 10, value } : undefined}
        codeTextStyle={[{ fontFamily: FONTS.REGULAR }, codeTextStyle]}
        textInputStyle={[{ fontFamily: FONTS.REGULAR }, textInputStyle]}
        textContainerStyle={[
          { backgroundColor: COLORS.grey, borderRadius: 4, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
          textContainerStyle,
        ]}
        containerStyle={[
          {
            width: '100%',
            height: THEME_INPUT_HEIGHT * 4,
            borderWidth: 1,
            borderRadius: 4,
            backgroundColor: colors.white,
            borderColor: colors.muted[300],
          },
          containerStyle,
        ]}
      />
      {(errorText || infoText || successText) && (
        <HStack space={2} alignItems={'center'} color={colors.info[300]}>
          <Ionicons
            color={errorText ? colors.danger[100] : infoText ? colors.info[500] : colors.success[300]}
            name="information-circle-sharp"
          />
          <BodyText text={errorText ?? infoText ?? successText ?? ''} color={colors.gray[400]} fontSize={12} />
        </HStack>
      )}
    </FormControl>
  );
};

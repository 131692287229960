import { FontAwesome } from '@expo/vector-icons';
import { HStack, Icon, StatusBar, Text, View } from 'native-base';
import { useRef } from 'react';
import { Dimensions } from 'react-native';
import LottieView from 'lottie-react-native';

const { width } = Dimensions.get('window');

const Slide = ({ item, currentSlideIndex }: any) => {
  const animation = useRef(null);
  const carouselRef = useRef<any>(null);

  const goToPrevSlide = () => {
    carouselRef.current?.scrollTo({ count: -1, animated: true });
  };

  const skip = () => {
    carouselRef.current?.scrollTo({ count: 3, animated: true });
  };

  return (
    <View alignItems="center">
      <StatusBar />
      {currentSlideIndex === 2 ? (
        <HStack alignItems="center" justifyContent="space-between" px="4" py="2" style={{ width }}>
          <HStack alignItems="center">
            <Icon as={FontAwesome} name="angle-left" />
            <Text onPress={goToPrevSlide}>Back</Text>
          </HStack>
        </HStack>
      ) : currentSlideIndex === 0 ? (
        <HStack alignItems="center" justifyContent="space-between" px="4" py="2" style={{ width }}>
          <HStack alignItems="center"></HStack>
          <Text fontFamily="AvertaStdBold" onPress={skip}>
            Skip
          </Text>
        </HStack>
      ) : (
        <HStack alignItems="center" justifyContent="space-between" px="4" py="2" style={{ width }}>
          <HStack alignItems="center">
            <Icon as={FontAwesome} name="angle-left" />
            <Text onPress={goToPrevSlide}>Back</Text>
          </HStack>
          <Text fontFamily="AvertaStdBold" onPress={skip}>
            Skip
          </Text>
        </HStack>
      )}
      <View style={{ width, height: '75%' }}>
        <LottieView ref={animation} autoPlay loop source={item?.lottie} resizeMode="contain" />
      </View>
      <View>
        <Text fontFamily="AvertaStd" fontSize="2xl" textAlign="center">
          {item?.title}
        </Text>
        <Text textAlign="center" mt="2" px="4" style={{ width }}>
          {item?.description}
        </Text>
      </View>
    </View>
  );
};

export default Slide;

import { getItem, saveItem } from 'src/utils/storage/store';

export const WELCOME_SCREEN_VISITED_KEY = 'onboardingScreenVisited';

export const setHasOnBoarded = async () => {
  try {
    await saveItem(WELCOME_SCREEN_VISITED_KEY, true);
  } catch (e) {
    // TODO handle the  error
  }
};

export const getHasOnBoarded = async (): Promise<boolean> => {
  try {
    const hasVisitedWelcomeScreen = await getItem(WELCOME_SCREEN_VISITED_KEY);
    return !!hasVisitedWelcomeScreen;
  } catch (e) {
    return false;
  }
};

import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { EnvkeysEnum, getEnvVariable } from 'src/utils/environment.utils';

export const getGraphqlUrl = () => {
  let URL = getEnvVariable(EnvkeysEnum.GATEWAY_URL) ?? '';
  const ENV = getEnvVariable(EnvkeysEnum.ENV);
  const IP_ADDRESS = Constants.manifest2?.extra?.expoClient?.extra?.hostUri ?? Constants.manifest?.extra?.hostUri;

  switch (Platform.OS) {
    case 'ios':
    case 'android':
      if (ENV === 'development' && IP_ADDRESS) URL = `http://${IP_ADDRESS}:4000/graphql`;
      break;
    default:
      break;
  }

  return URL;
};
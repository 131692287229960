import React, { ReactNode } from 'react';
import { View, Text } from 'react-native';

import { captureException } from 'src/utils/sentry';

export const ERROR_BOUNDARY_TEST_ID = 'ErrorBoundary';

export interface ErrorBoundaryProps {
  children: ReactNode | ReactNode[];
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // TODO add implementation when we want to start logging errors to a service.
  componentDidCatch(error: any) {
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <View>
          <Text testID={ERROR_BOUNDARY_TEST_ID}>Something went wrong.</Text>
        </View>
      );
    }

    return this.props.children;
  }
}

import { HStack, Image, Box, Link } from 'native-base';

const Links_Main = [
  { title: 'Manage Booking', href: '/manage-booking' },
  { title: 'Online Check-in', href: '/check-in' },
];

export const NavigationHeader = () => {
  return (
    <Box w="100%" alignItems="center" mb="4" px="8">
      <HStack mt="2" space="3" alignItems="center" w="100%" maxW="1100" justifyContent={'space-between'}>
        <Link mr="6" href="/">
          <Image
            source={require('src/screens/landing/images/logo.png')}
            alt="Alternate Text"
            style={{ width: 94, height: 50 }}
            resizeMode="contain"
          />
        </Link>

        <HStack space={5}>
          {Links_Main.map(item => (
            <Link
              key={item.title}
              href={item.href}
              isUnderlined={false}
              _text={{
                color: 'coolGray.500',
                fontWeight: 'light',
              }}
              _hover={{
                _text: {
                  color: 'grayZomato.700',
                },
              }}
            >
              {item.title}
            </Link>
          ))}
        </HStack>
      </HStack>
    </Box>
  );
};

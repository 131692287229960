import { Entypo } from '@expo/vector-icons';
import { HStack, Icon, IconButton } from 'native-base';
import { useState } from 'react';
import { BodyText } from '../typography/BodyText';

export interface NumberCounterProps {
  onChange?: (value: number) => void;
  allowNegative?: boolean;
  defaultValue?: number;
}

export const NumberCounter = ({ onChange, allowNegative = false, defaultValue }: NumberCounterProps) => {
  const [count, setCount] = useState<number>(defaultValue ?? 0);

  const onCountAction = (action: 'add' | 'deduct') => {
    let newCount = 0;
    if (action === 'add') {
      newCount = count + 1;
    }

    if (action === 'deduct') {
      if (allowNegative || count > 0) {
        newCount = count - 1;
      }
    }

    setCount(newCount);
    if (onChange) {
      onChange(newCount);
    }
  };
  return (
    <HStack space={3} alignItems="center">
      <IconButton icon={<Icon as={Entypo} name="circle-with-minus" onPress={() => onCountAction('deduct')} />} />
      <BodyText text={`${count}`} minWidth={4} textAlign="center" />
      <IconButton icon={<Icon as={Entypo} name="circle-with-plus" onPress={() => onCountAction('add')} />} />
    </HStack>
  );
};

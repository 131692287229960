import { Box, Center, Flex, HStack, Image, Pressable, Stack, VStack, useTheme } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SubHeader } from 'src/components/typography/SubHeader';

export interface UspProps {
  icon: any;
  title: string;
  description: string;
}

export const Usp: FC<UspProps> = ({ title, description, icon }) => {
  return (
    <Box shadow={2} width={'46%'} mt={10} background={'white'} p={5} borderRadius={2}>
      <VStack space={2}>
        <Image source={icon} style={{ width: 48, height: 48 }} />
        <SubHeader text={title} bold fontSize={25} color={'primary.400'} />
        <BodyText text={description} />
      </VStack>
    </Box>
  );
};

export const MobileApp: FC = () => {
  const { space } = useTheme();
  return (
    <Stack direction={{ base: 'column-reverse', md: 'row' }} justifyContent="center" space={4} p={space[2]} zIndex={-1} mt={10}>
      <VStack space={4}>
        <SubHeader text="An app that keeps you in control" fontSize={38} color="black" lineHeight="md" fontWeight="bold" />
        <BodyText
          text="Benefit from app exclusive offers, personalised 
notifications, seamless payment functions, 
journey tracking and more  "
          color="black"
          fontSize={16}
          lineHeight="md"
          textAlign="left"
        />
        <HStack mt="2" space={{ base: '1', lg: 2 }}>
          <Pressable>
            <Image
              source={{
                uri: 'https://b.zmtcdn.com/data/webuikit/9f0c85a5e33adb783fa0aef667075f9e1556003622.png',
              }}
              alt="Alternate Text"
              resizeMode="contain"
              w={{ base: 40 }}
              h={12}
            />
          </Pressable>
          <Pressable>
            <Image
              source={{
                uri: 'https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png',
              }}
              alt="Alternate Text"
              resizeMode="contain"
              w={{ base: 40 }}
              h={12}
            />
          </Pressable>
        </HStack>
      </VStack>
      <Image source={require('src/assets/images/mobile-mockup.png')} size="2xl" resizeMode="contain" alignSelf="center" />
    </Stack>
  );
};

import { useFonts } from 'expo-font';
import { extendTheme, NativeBaseProvider } from 'native-base';
import { ReactNode } from 'react';
import { LoadingState } from 'src/components/loading-state/LoadingState';

import { colorsOverride } from 'src/providers/design-provider/token-overrides/colors';
import { fontOverride } from 'src/providers/design-provider/token-overrides/font';
import { fontSizeOverride } from 'src/providers/design-provider/token-overrides/font-sizes';
import { selectComponentTheme } from 'src/providers/design-provider/token-overrides/select';
import { inputComponentTheme } from 'src/providers/design-provider/token-overrides/input';
import { LinearGradient } from 'expo-linear-gradient';

export const DesignProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [loaded] = useFonts({
    AvertaStd: require('src/assets/fonts/AvertaStd-Regular.ttf'),
    AvertaStdSemiBold: require('src/assets/fonts/AvertaStd-Semibold.ttf'),
    AvertaStdBold: require('src/assets/fonts/AvertaStd-Semibold.ttf'),
  });

  const themeOverrides = {
    colors: colorsOverride,
    fontSizes: fontSizeOverride,
    fonts: fontOverride,
    components: {
      Select: selectComponentTheme,
      Input: inputComponentTheme,
    },
  };

  const theme = extendTheme(themeOverrides);

  const config = {
    dependencies: {
      'linear-gradient': LinearGradient,
    },
  };

  if (!loaded) {
    return <LoadingState />;
  }

  return (
    <NativeBaseProvider config={config} theme={theme}>
      {children}
    </NativeBaseProvider>
  );
};

import { Feather } from '@expo/vector-icons';
import { Box, Center, Icon, Pressable, useTheme } from 'native-base';
import { GestureResponderEvent } from 'react-native';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';

export interface AddPersengerButtonProps {
  onPress: ((event: GestureResponderEvent) => void) | null | undefined;
}

export const AddPassengerButton = ({ onPress }: AddPersengerButtonProps) => {
  const { colors } = useTheme();
  return (
    <Pressable onPress={onPress}>
      {({ isHovered, isFocused, isPressed }) => {
        const fontColor = isPressed || isFocused || isHovered ? colors.white : colors.primary[400];
        const bgColor = isPressed || isFocused || isHovered ? colors.primary[400] : colors.white;
        return (
          <Box
            background={bgColor}
            marginTop={5}
            style={{ borderWidth: 0.5, borderColor: colors.primary[400], borderRadius: 4, height: 95 }}
            alignItems="center"
            justifyContent="center"
          >
            <Center flexDirection={'row'}>
              <SubSubHeader text="Add passenger" color={fontColor} />
              <Icon as={Feather} name="plus" color={fontColor} />
            </Center>
          </Box>
        );
      }}
    </Pressable>
  );
};

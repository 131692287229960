import { Box, Image } from 'native-base';
import { FC } from 'react';
import { ContentContainer } from 'src/components/container/ContentContainer';
import FlightScroll from 'src/screens/landing/components/FlightScroll';
import { MobileApp } from 'src/screens/landing/components/MobileApp';
import { SearchBox } from 'src/screens/landing/components/SeachBox';
import { BodyText } from 'src/components/typography/BodyText';

export const Landing: FC = () => {
  return (
    <ContentContainer contentFullWidth>
      <Box
        h={{ base: 290, md: 280, lg: 400 }}
        bg={{
          linearGradient: {
            colors: ['#0A1221', '#124276'],
            start: [0, 0],
            end: [0, 1],
          },
        }}
        justifyContent={'center'}
      >
        <Image
          source={require('src/screens/landing/images/background.jpg')}
          style={{ position: 'absolute', height: '100%', width: '100%', opacity: 0.1 }}
          resizeMode="stretch"
        />
        <Box width="80%" alignSelf="center">
          <BodyText
            text="Experience Incomparable Premium"
            color="white"
            fontSize={{ base: 24, md: '2xl', lg: '5xl' }}
            lineHeight="md"
          />
          <BodyText text="Travel" color="white" fontSize={{ base: 24, md: '2xl', lg: '5xl' }} lineHeight="md" fontWeight="bold" />
        </Box>
      </Box>
      <Box pb={15} bg="coolGray.100">
        <SearchBox />
        <MobileApp />
      </Box>
      <Box pb={20} bg="coolGray.100">
        <FlightScroll />
      </Box>
    </ContentContainer>
  );
};

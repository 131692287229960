import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { GetTerminalsQuery } from 'src/graphql/ticketing_api/queries/__generated__/get-terminal.generated';

export type GetTerminalsQuery_getTerminals_Terminal = GetTerminalsQuery['getTerminals'][number];

const GET_TERMINALS_QUERY = gql`
  query GetTerminals {
    getTerminals {
      id
      name
      address {
        state
        country
      }
    }
  }
`;

export const useGetTerminalsQuery = (): QueryResult<GetTerminalsQuery> =>
  useQueryErrorNotification<GetTerminalsQuery>(GET_TERMINALS_QUERY);

import * as Application from 'expo-application';
import { Center, VStack } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { BodyText } from 'src/components/typography/BodyText';

export const MoreScreen = () => {
  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      <Center flex={1}>
        <VStack space={3}>
          <BodyText text={`App version: v${Application.nativeApplicationVersion}`} />
        </VStack>
      </Center>
    </ContentContainer>
  );
};

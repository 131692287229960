import { FC } from 'react';
import { Path, Polygon, G, Circle, ClipPath, Rect, Defs } from 'react-native-svg';

import BaseSvg, { BaseSvgProps } from 'src/components/base-svg/BaseSvg';

export type TripIllustrationImageProps = Partial<BaseSvgProps>;

export const TripIllustrationImage: FC<TripIllustrationImageProps> = ({ viewBox, height, width, fill }) => (
  <BaseSvg viewBox={{ width: viewBox?.width ?? 318, height: viewBox?.height ?? 21 }} width={width ?? 318} height={height ?? 21}>
    <G clipPath="url(#clip0_2141_567)">
      <Path
        d="M19.0625 5.5H18.75V3.625C18.75 1.875 14.875 0.5 10 0.5C5.125 0.5 1.25 1.875 1.25 3.625V5.5H0.9375C0.419922 5.5 0 5.91953 0 6.4375V9.5625C0 10.0801 0.419922 10.5 0.9375 10.5H1.25V16.75C1.25 17.4402 1.80977 18 2.5 18V19.25C2.5 19.9402 3.05977 20.5 3.75 20.5H5C5.69023 20.5 6.25 19.9402 6.25 19.25V18H13.75V19.25C13.75 19.9402 14.3098 20.5 15 20.5H16.25C16.9402 20.5 17.5 19.9402 17.5 19.25V18H17.75C18.375 18 18.75 17.5 18.75 17V10.5H19.0625C19.5801 10.5 20 10.0801 20 9.5625V6.4375C20 5.91953 19.5801 5.5 19.0625 5.5ZM4.375 16.125C3.68477 16.125 3.125 15.5652 3.125 14.875C3.125 14.1848 3.68477 13.625 4.375 13.625C5.06523 13.625 5.625 14.1848 5.625 14.875C5.625 15.5652 5.06523 16.125 4.375 16.125ZM5 11.75C4.30977 11.75 3.75 11.1902 3.75 10.5V5.5C3.75 4.80977 4.30977 4.25 5 4.25H15C15.6902 4.25 16.25 4.80977 16.25 5.5V10.5C16.25 11.1902 15.6902 11.75 15 11.75H5ZM15.625 16.125C14.9348 16.125 14.375 15.5652 14.375 14.875C14.375 14.1848 14.9348 13.625 15.625 13.625C16.3152 13.625 16.875 14.1848 16.875 14.875C16.875 15.5652 16.3152 16.125 15.625 16.125Z"
        fill={fill ?? '#111111'}
        fillOpacity="0.8"
      />
    </G>
    <Circle cx="30" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="39" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="48" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="60" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="69" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="78" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="90" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="99" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="108" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="120" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="129" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="138" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="150" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="159" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="168" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="180" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="189" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="198" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="210" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="219" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="228" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="240" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="249" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="258" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="270" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="279" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <Circle cx="288" cy="10.5" r="2" fill={fill ?? '#111111'} fillOpacity="0.8" />
    <G clipPath="url(#clip1_2141_567)">
      <Path
        d="M317.062 5.5H316.75V3.625C316.75 1.875 312.875 0.5 308 0.5C303.125 0.5 299.25 1.875 299.25 3.625V5.5H298.938C298.42 5.5 298 5.91953 298 6.4375V9.5625C298 10.0801 298.42 10.5 298.938 10.5H299.25V16.75C299.25 17.4402 299.81 18 300.5 18V19.25C300.5 19.9402 301.06 20.5 301.75 20.5H303C303.69 20.5 304.25 19.9402 304.25 19.25V18H311.75V19.25C311.75 19.9402 312.31 20.5 313 20.5H314.25C314.94 20.5 315.5 19.9402 315.5 19.25V18H315.75C316.375 18 316.75 17.5 316.75 17V10.5H317.062C317.58 10.5 318 10.0801 318 9.5625V6.4375C318 5.91953 317.58 5.5 317.062 5.5ZM302.375 16.125C301.685 16.125 301.125 15.5652 301.125 14.875C301.125 14.1848 301.685 13.625 302.375 13.625C303.065 13.625 303.625 14.1848 303.625 14.875C303.625 15.5652 303.065 16.125 302.375 16.125ZM303 11.75C302.31 11.75 301.75 11.1902 301.75 10.5V5.5C301.75 4.80977 302.31 4.25 303 4.25H313C313.69 4.25 314.25 4.80977 314.25 5.5V10.5C314.25 11.1902 313.69 11.75 313 11.75H303ZM313.625 16.125C312.935 16.125 312.375 15.5652 312.375 14.875C312.375 14.1848 312.935 13.625 313.625 13.625C314.315 13.625 314.875 14.1848 314.875 14.875C314.875 15.5652 314.315 16.125 313.625 16.125Z"
        fill={fill ?? '#111111'}
        fillOpacity="0.8"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_2141_567">
        <Rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </ClipPath>
      <ClipPath id="clip1_2141_567">
        <Rect width="20" height="20" fill="white" transform="translate(298 0.5)" />
      </ClipPath>
    </Defs>
  </BaseSvg>
);

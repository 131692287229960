import { View, Button, VStack } from 'native-base';

import { ContentContainer } from 'src/components/container/ContentContainer';
import { SubHeader } from 'src/components/typography/SubHeader';
import { AddPassengerButton } from './components/AddPassengerButton';
import { PassengerItem } from './components/PassengerListItem';
import { useLinkProps } from '@react-navigation/native';
import {
  CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME,
  PAYMENT_CONFIRMATION_SCREEN_NAME,
  SELECT_SEAT_NAME,
} from 'src/navigation/constants';
import { CreatePassengerInput } from 'src/graphql/ticketing_api/mutations/create-reservation';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { ReservationHeader } from './components/ReservationHeader';
import { isWeb } from 'src/utils/environment.utils';

export const PassengertInformation: React.FC = () => {
  const { passengers, search, setEditablePassengerIndex, departureSchedule } = useReservation();

  const { onPress: goToSelectSeat } = useLinkProps({ to: { screen: SELECT_SEAT_NAME } });
  const { onPress: goToPaymentConfirmation } = useLinkProps({ to: { screen: PAYMENT_CONFIRMATION_SCREEN_NAME } });
  const { onPress: goToCreateOrEditPassengerInfo } = useLinkProps({
    to: { screen: CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME },
  });

  const onAddNewPassenger = () => {
    goToCreateOrEditPassengerInfo();
  };

  const passengerCount = (search?.adult ?? 0) + (search?.child ?? 0) + (search?.infant ?? 0);
  const isDisabled = passengerCount > (passengers?.length ?? 0);
  const isCompleted = passengerCount === passengers?.length;

  const onEditPassenger = (_: CreatePassengerInput, index: number) => {
    setEditablePassengerIndex(index);
    goToCreateOrEditPassengerInfo();
  };

  return (
    <ContentContainer
      allWhiteBackground={isWeb() ? false : true}
      showFooter={false}
      header={<ReservationHeader />}
      headerFullWidth={true}
    >
      <View mt={4}>
        <SubHeader text={`${passengers?.length}/${passengerCount} Entries`} />
        <View marginTop={3}>
          <VStack space={1}>
            {passengers?.map((p, i) => (
              <PassengerItem key={`${i}`} index={i} passenger={p} onPassengerEdited={onEditPassenger} />
            ))}
          </VStack>
        </View>
        {!isCompleted && <AddPassengerButton onPress={onAddNewPassenger} />}
      </View>

      {isCompleted && (
        <Button
          style={{ marginTop: 100 }}
          onPress={departureSchedule?.trip.isCheckinAvailable ? goToSelectSeat : goToPaymentConfirmation}
          isDisabled={isDisabled}
        >
          Proceed
        </Button>
      )}
    </ContentContainer>
  );
};

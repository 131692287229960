import { ApolloError, ApolloQueryResult, LazyQueryExecFunction, QueryResult } from '@apollo/client';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Exact } from 'src/graphql/generated/types';
import {
  GetReservationBySurnameQuery,
  GetReservationBySurnameQueryVariables,
} from 'src/graphql/ticketing_api/queries/__generated__/get-reservation-by-surname.generated';
import { useLazyGetReservationBySurnameQuery } from 'src/graphql/ticketing_api/queries/get-reservation-by-surname';

export interface BookingContextType {
  booking?: GetReservationBySurnameQuery;
  bookingError?: ApolloError;
  loading?: boolean;
  getReservation: (reference: string, surname: string) => Promise<any>;
  resetBooking: () => void;
  surname?: string;
  refetch?: (
    variables?: Partial<
      Exact<{
        reservationId: any;
        surname: any;
      }>
    >
  ) => Promise<ApolloQueryResult<GetReservationBySurnameQuery>>;
}

export const BookingContext = createContext<BookingContextType>({
  getReservation: async (reference: string, surname: string): Promise<any> => {},
  resetBooking: () => {},
});

export const useBooking = () => {
  if (!BookingContext) {
    throw Error('useBooking can not be used outside of an BookingContextProvider.');
  }
  return useContext(BookingContext);
};

export const BookingProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [getReservationBySurname, { error, loading, data: reservation, refetch }] = useLazyGetReservationBySurnameQuery();
  const [booking, setBooking] = useState<GetReservationBySurnameQuery>();
  const [surname, setSurname] = useState<string>();

  useEffect(() => {
    if (reservation) {
      setBooking(reservation);
    }
  }, [reservation]);

  const getReservation = useCallback(
    async (reference: string, surname: string) => {
      await getReservationBySurname({ variables: { reference, surname } });
      setSurname(surname);
    },
    [getReservationBySurname, setSurname]
  );

  const resetBooking = useCallback(() => setBooking(undefined), [setBooking]);

  return (
    <BookingContext.Provider value={{ getReservation, booking, bookingError: error, loading, resetBooking, refetch, surname }}>
      {children}
    </BookingContext.Provider>
  );
};

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Center, HStack, Icon, Pressable, Stack, VStack } from 'native-base';
import { FC, ReactNode } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SeatStatusEnum } from 'src/graphql/generated/types';
import { TripSeatMapQuery_tripSeatMap_Seat } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { SeatColorSheme, getSeatStatus } from 'src/screens/book-seat/ReservationUtils';
import { capitalize } from 'src/utils/string';

type ColorSchemeKey = keyof typeof SeatColorSheme;

export interface SeatSelectorProps {
  seatMap: (TripSeatMapQuery_tripSeatMap_Seat | undefined)[][];
  onSeatSelected: (seat: TripSeatMapQuery_tripSeatMap_Seat) => void;
  selectedSeatIds: number[];
}

export interface SeatRowProps {
  rowIndex: number;
  row: (TripSeatMapQuery_tripSeatMap_Seat | undefined)[];
  onSeatSelected: (seat: TripSeatMapQuery_tripSeatMap_Seat) => void;
  selectedSeatIds: number[];
}

export interface SeatBoxProps {
  seat?: TripSeatMapQuery_tripSeatMap_Seat;
  status?: SeatStatusEnum | 'selected';
  onPress: () => void;
  icon?: ReactNode;
}

export const SeatBox: FC<SeatBoxProps> = ({ seat, onPress, status, icon }) => {
  const background = status ? SeatColorSheme[status] : undefined;
  const disabled =
    !seat ||
    status === SeatStatusEnum.Blocked ||
    status === SeatStatusEnum.Reserved ||
    status === SeatStatusEnum.Occupied ||
    status === 'selected';
  return (
    <Pressable disabled={disabled} onPress={onPress}>
      <Box borderRadius={3} h={12} width={12} backgroundColor={background} justifyContent="center" alignItems="center">
        {icon ?? <BodyText text={seat?.number ?? ''} />}
      </Box>
    </Pressable>
  );
};

export const SeatRoW: FC<SeatRowProps> = ({ row, onSeatSelected, selectedSeatIds, rowIndex }) => {
  return (
    <HStack space={3}>
      {row.map((r, i) => {
        let icon = undefined;
        if (rowIndex === 0 && i === 0) icon = <Icon as={MaterialCommunityIcons} name="steering" size={8} />;
        return (
          <SeatBox
            key={`${i}_box`}
            seat={r}
            icon={icon}
            status={getSeatStatus(r, selectedSeatIds)}
            onPress={() => {
              const seat = row[i];
              if (seat) onSeatSelected(seat);
            }}
          />
        );
      })}
    </HStack>
  );
};

export const SeatSelector: FC<SeatSelectorProps> = ({ seatMap, onSeatSelected, selectedSeatIds }) => {
  return (
    <Center>
      <VStack space={3}>
        {seatMap.map((row, i) => (
          <SeatRoW rowIndex={i} key={`${i}_seat_row`} row={row} onSeatSelected={onSeatSelected} selectedSeatIds={selectedSeatIds} />
        ))}
      </VStack>
      <HStack space={4} marginTop={10}>
        {(Object.keys(SeatColorSheme) as ColorSchemeKey[]).map((key: ColorSchemeKey) => (
          <VStack space={1} alignItems="center" key={key}>
            <Box backgroundColor={SeatColorSheme[key]} height={3} width={3} borderRadius={2} />
            <BodyText
                text={key === SeatStatusEnum.Blocked ? capitalize('unavailable') : capitalize(key)}
            />
          </VStack>
        ))}
      </HStack>
    </Center>
  );
};

import { AntDesign } from '@expo/vector-icons';
import { Center, Icon, VStack } from 'native-base';
import { useState } from 'react';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { AvailableSchedule } from 'src/providers/reservation-provider/ReservationProvider';
import { AvailabilityListItem } from 'src/screens/book-seat/components/AvailabilityListItem';
import { parseDateTimeStringToMoment } from 'src/utils/date-time';

export interface AvailabilityListProps {
  schedules: AvailableSchedule[] | undefined;
  onScheduleSelected?: (schedule: AvailableSchedule) => void;
  onContinue: () => void;
}

export const AvailabilityList: React.FC<AvailabilityListProps> = ({ schedules, onScheduleSelected, onContinue }) => {
  const [activeSchedule, setActiveSchedule] = useState<AvailableSchedule>();

  const setSelectedSchedule = (scheduleId: string) => {
    const selectedSchedule = schedules?.find(s => s.id === scheduleId);
    if (selectedSchedule) {
      setActiveSchedule(selectedSchedule);
      if (onScheduleSelected) {
        onScheduleSelected(selectedSchedule);
      }
    }
  };

  return (
    <VStack space={5}>
      {schedules?.map(s => (
        <AvailabilityListItem
          key={s.id}
          amount={s.totalFare}
          time={parseDateTimeStringToMoment(s.trip.date, s.trip.time).format('HH:mm A')}
          availableSeats={1}
          onItemSelect={() => setSelectedSchedule(s.id)}
          travelClass={s.trip.vehicleClass.name}
          onContinue={onContinue}
          schedule={s}
          isActive={activeSchedule?.id === s.id}
        />
      ))}
      {schedules && !schedules.length && (
        <Center>
          <Icon as={AntDesign} name="inbox" size={24} />
          <SubSubHeader text="No schedules available for this date" />
        </Center>
      )}
    </VStack>
  );
};

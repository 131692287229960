import { ITextProps, Text, useTheme } from 'native-base';
import { InterfaceTextProps } from 'native-base/lib/typescript/components/primitives/Text/types';

export interface TextProps {
  text: string;
  bold?: boolean;
  [key: string]: any;
}

export type TypographyProps = TextProps & InterfaceTextProps<ITextProps>;

export const BodyText = ({ text, bold, ...props }: TypographyProps) => {
  const { fontSizes, fonts } = useTheme();

  if (!text) return null;

  return (
    <Text fontSize={fontSizes.sm} {...props} fontFamily={bold ? fonts.heading : undefined} fontWeight={bold ? 600 : undefined}>
      {text}
    </Text>
  );
};

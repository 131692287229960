import { Button, Container, Divider, Pressable, StatusBar, Text, View, VStack } from 'native-base';
import LottieView from 'lottie-react-native';
import { Dimensions } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'src/types/root.type';
import { GUEST_SCREEN_NAME, MAIN_SCREEN_NAME } from 'src/navigation/constants';
import { StackActions, useLinkProps } from '@react-navigation/native';

type GuestScreenProps = NativeStackScreenProps<RootStackParamList, typeof GUEST_SCREEN_NAME>;

const { width } = Dimensions.get('window');

export const GuestConfirmation = ({ navigation }: GuestScreenProps) => {
  const { onPress: goToHome } = useLinkProps({
    to: { screen: MAIN_SCREEN_NAME },
    action: StackActions.replace(MAIN_SCREEN_NAME),
  });

  return (
    <VStack alignItems="center" w="100%" h="full" backgroundColor="#fff">
      <StatusBar />
      <View style={{ width, height: '50%' }}>
        <LottieView loop autoPlay source={require('../../../assets/man-walking.json')} resizeMode="contain" />
      </View>
      <Container>
        <Text fontFamily="AvertaStd" fontSize="lg" textAlign="center" fontWeight="light">
          Sign up now and enjoy great discounts on every Booking
        </Text>
      </Container>
      <Container alignItems="center" width="full" mt="8">
        <Button size="lg" fontFamily="AvertaStdBold" variant="outline" _text={{ color: '#273C75' }} alignSelf="center" w="full" mb="3">
          Sign Up
        </Button>
        <Button size="lg" fontFamily="AvertaStdBold" backgroundColor="#273C75" alignSelf="center" w="full">
          Login
        </Button>
      </Container>
      <View flexDirection="row" alignItems="center" justifyContent="space-evenly" mt="8">
        <Divider w="24" h="0.5" backgroundColor="gray.200" />
        <Text mx="8" fontFamily="AvertaStdBold" fontSize="lg" fontWeight="semibold">
          OR
        </Text>
        <Divider w="24" h="0.5" backgroundColor="gray.200" />
      </View>
      <Pressable onPress={goToHome}>
        <Text fontFamily="AvertaStdBold" fontSize="lg" textAlign="center" color="#273C75" mt="5" underline>
          Continue as Guest
        </Text>
      </Pressable>
    </VStack>
  );
};

import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { usePaystackPayment } from 'react-paystack';
import { useCallback, useEffect, useState } from 'react';
import { CreateReservationMutation } from 'src/graphql/ticketing_api/mutations/__generated__/create-reservation.generated';
import { EnvkeysEnum, getEnvVariable } from 'src/utils/environment.utils';
import { FlutterwaveConfig, FlutterWaveResponse } from 'flutterwave-react-v3/dist/types';
import { PaystackProps } from 'react-paystack/dist/types';

export const usePayment = (reservation: CreateReservationMutation | undefined) => {
  const [res, setRes] = useState<CreateReservationMutation | undefined>(reservation);
  const primaryPassenger = res?.createOrEditReservation.passengers.find(p => p.isPrimaryPassenger);
  const totalFare = res?.createOrEditReservation.totalFare;

  const config: FlutterwaveConfig = {
    public_key: getEnvVariable(EnvkeysEnum.FLUTTERWAVE_KEY) ?? '',
    tx_ref: res?.createOrEditReservation.transaction.reference ?? '',
    amount: totalFare ?? 0,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: primaryPassenger?.email ?? '',
      phone_number: primaryPassenger?.phone ?? '',
      name: primaryPassenger?.fullName ?? '',
    },
    customizations: {
      title: getEnvVariable(EnvkeysEnum.ORGANIZATION_NAME) ?? '',
      description: 'Reservation payment',
      logo: 'https://ratedwhat.com/storage/post_image/a70f842d-2039-4654-9c3f-0b68773ccbeb1628452384.png',
    },
  };

  const paystackConfig: PaystackProps = {
    reference: res?.createOrEditReservation.transaction.reference ?? '',
    email: res?.createOrEditReservation?.reservation.contactEmail ?? '',
    firstname: primaryPassenger?.fullName,
    channels: ['bank', 'bank_transfer', 'card'],
    subaccount: getEnvVariable(EnvkeysEnum.PAYSTACK_SUBACCOUNT_CODE) ?? undefined,
    //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    amount: (totalFare ?? 0) * 100,
    publicKey: getEnvVariable(EnvkeysEnum.PAYSTACK_KEY) ?? '',
  };

  const handleFlutterPayment = useFlutterwave(config);
  const initializePayment = usePaystackPayment(paystackConfig);

  const onFlwPaymentCompleted = async (response: FlutterWaveResponse, callback: () => void) => {
    if (response.status === 'completed') {
      callback();
    }
    closePaymentModal();
  };

  const onClose = () => {
    console.log('Closings!');
  };

  const processPayment = useCallback(
    (callback: () => void) => {
      if (res?.createOrEditReservation.transaction.provider === 'flutterwave') {
        handleFlutterPayment({
          callback: (response: FlutterWaveResponse) => onFlwPaymentCompleted(response, callback),
          onClose,
        });
      } else if (res?.createOrEditReservation.transaction.provider === 'paystack') {
        initializePayment(callback, onClose);
      }
    },
    [res, initializePayment, handleFlutterPayment]
  );

  useEffect(() => {
    if (reservation) {
      setRes(reservation);
    }
  }, [reservation]);

  return { processPayment };
};

import { Text, useTheme } from 'native-base';
import { TypographyProps } from 'src/components/typography/BodyText';

export const SubHeader = ({ text, ...props }: TypographyProps) => {
  const { fonts, fontSizes } = useTheme();

  if (!text) return null;

  return (
    <Text fontFamily={fonts.heading} fontSize={fontSizes.xl} {...props}>
      {text}
    </Text>
  );
};

import { LazyQueryResultTuple, QueryResult, gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetAvailableScheduleQuery,
  GetAvailableScheduleQueryVariables,
} from 'src/graphql/ticketing_api/queries/__generated__/get-availability.generated';
import { GetAvailabilityScheduleInput } from 'src/graphql/generated/types';
import { TRAVEL_SCHEDULE_FRAGMENT } from 'src/graphql/ticketing_api/fragments/travel-schedule.fragment';

const GET_AVAILABILITY_QUERY = gql`
  query GetAvailableSchedule($input: GetAvailabilityScheduleInput!) {
    getAvailableSchedule(input: $input) {
      departures {
        ...TravelScheduleFragment
      }
      arrivals {
        ...TravelScheduleFragment
      }
    }
  }
  ${TRAVEL_SCHEDULE_FRAGMENT}
`;

export const useGetAvailablityQuery = (
  input: GetAvailabilityScheduleInput
): QueryResult<GetAvailableScheduleQuery, GetAvailableScheduleQueryVariables> =>
  useQuery<GetAvailableScheduleQuery, GetAvailableScheduleQueryVariables>(GET_AVAILABILITY_QUERY, {
    variables: { input },
    fetchPolicy: 'network-only',
  });

export const useGetAvailablityLazyQuery = (): LazyQueryResultTuple<GetAvailableScheduleQuery, GetAvailableScheduleQueryVariables> =>
  useLazyQuery<GetAvailableScheduleQuery, GetAvailableScheduleQueryVariables>(GET_AVAILABILITY_QUERY, { fetchPolicy: 'network-only' });

import React from 'react';

import { Text, useTheme } from 'native-base';
import { TypographyProps } from 'src/components/typography/BodyText';

export const SmallLabel: React.FC<TypographyProps> = ({ text, bold, color, ...props }) => {
  const { fontSizes, fonts, colors } = useTheme();

  if (!text) return null;

  const labelColor = color ?? colors.gray[400];
  return (
    <Text
      color={labelColor}
      fontSize={fontSizes.xs}
      {...props}
      fontFamily={bold ? fonts.heading : undefined}
      fontWeight={bold ? 600 : undefined}
    >
      {text}
    </Text>
  );
};

import React from 'react';
import { ActivityIndicator, View } from 'react-native';

export const LOADING_STATE_TEST_ID = 'LoadingState';

export const LoadingState = () => {
  return (
    <View testID={LOADING_STATE_TEST_ID}>
      <ActivityIndicator size="large" />
    </View>
  );
};

import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { TripSeatMapQuery, TripSeatMapQueryVariables } from './__generated__/get-trip-seatmap.generated';

export type TripSeatMapQuery_tripSeatMap_Seat = TripSeatMapQuery['tripSeatMap']['seats'][number];

const GET_TRIP_SEATMAP_QUERY = gql`
  query TripSeatMap($tripId: String!) {
    tripSeatMap(tripId: $tripId) {
      id
      organizationId
      endSeatRow
      length
      width
      startSeatRow
      seats {
        id
        number
        column
        row
        status
      }
    }
  }
`;

export const useGetTripSeatMapQuery = (tripId: string): QueryResult<TripSeatMapQuery, TripSeatMapQueryVariables> =>
  useQueryErrorNotification<TripSeatMapQuery, TripSeatMapQueryVariables>(GET_TRIP_SEATMAP_QUERY, {
    variables: { tripId },
    fetchPolicy: 'network-only',
  });

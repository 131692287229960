import { useState } from 'react';
import { Actionsheet, Box, Button, Flex, FormControl, Pressable, View, VStack, Divider, useTheme } from 'native-base';

import { COLORS, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl/types';
import { BodyText } from 'src/components/typography/BodyText';
import { NumberCounter } from 'src/components/number-counter/NumberCounter';
import { SubHeader } from 'src/components/typography/SubHeader';

export type PassengerSet = {
  adult: number;
  child: number;
  infant: number;
};
export interface PassengerCounterProps {
  label?: string;
  defaults?: PassengerSet;
  onComplete?: (set: PassengerSet) => void;
  trigger?: React.ReactNode;
}

export const PassengerCounter = ({ label, defaults, onComplete, trigger, ...props }: PassengerCounterProps & IFormControlProps) => {
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const [adult, setAdult] = useState<number>(defaults?.adult ?? 1);
  const [child, setChild] = useState<number>(defaults?.child ?? 1);
  const [infant, setInfant] = useState<number>(defaults?.infant ?? 1);
  const [total, setTotal] = useState<number>(adult + child + infant);
  const { colors } = useTheme();

  const onDoneClicked = () => {
    if (onComplete) {
      onComplete({ adult, child, infant });
    }
    setTotal(adult + child + infant);
    setShowActionSheet(false);
  };

  return (
    <>
      {trigger && <Pressable onPress={() => setShowActionSheet(true)}>{trigger}</Pressable>}
      {!trigger && (
        <FormControl {...props} zIndex={-1}>
          {label && <FormControl.Label>{label}</FormControl.Label>}
          <Pressable onPress={() => setShowActionSheet(true)}>
            <View
              height={THEME_INPUT_HEIGHT}
              backgroundColor={COLORS.grey}
              justifyContent="center"
              py="2"
              px="3"
              borderWidth={1}
              borderColor={colors.muted[300]}
              borderRadius={4}
            >
              <BodyText text={`${total}`} />
            </View>
          </Pressable>
        </FormControl>
      )}
      <Actionsheet isOpen={showActionSheet} onClose={() => setShowActionSheet(false)}>
        <Actionsheet.Content px={10}>
          <Box w="100%" marginBottom={5}>
            <SubHeader text="Set Passengers" />
            <Divider my="2" bg={colors.muted[200]} />
          </Box>
          <VStack space={4} width="100%">
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <BodyText text="Adults 8 years and above" /> <NumberCounter defaultValue={adult} onChange={setAdult} />
            </Flex>
            <Flex direction="row" alignItems="center" justifyContent="space-between">
              <BodyText text="Children 3-7 years" /> <NumberCounter defaultValue={child} onChange={setChild} />
            </Flex>
            <Flex direction="row" alignItems="center" justifyContent="space-between">
              <BodyText text="Infant 0-2 years" /> <NumberCounter defaultValue={infant} onChange={setInfant} />
            </Flex>
            <Button size="lg" mt={8} onPress={onDoneClicked}>
              Done
            </Button>
          </VStack>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};

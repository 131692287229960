import { Dimensions, SafeAreaView } from 'react-native';
import { useRef, useState } from 'react';
import { Button, StatusBar, View } from 'native-base';
import Carousel from 'react-native-reanimated-carousel';
import styles from './styles/styles';
import Slide from './onboarding-slide/OnboardingSlide';
import { StackActions, useLinkProps } from '@react-navigation/native';
import { MAIN_SCREEN_NAME } from 'src/navigation/constants';
import { setHasOnBoarded } from 'src/utils/onboarding';

const { width, height } = Dimensions.get('window');

const slides = [
  {
    id: 1,
    title: 'Travel More with Okeyson',
    description: 'Get the best travel experience with Okeyson and enjoy seamless service and discounts.',
    lottie: require('../../../assets/travel-is-fun.json'),
  },
  {
    id: 2,
    title: 'Choose Your Destination.',
    description: 'Plan your trip, choose your destination and enjoy great discounts off all trips.',
    lottie: require('../../../assets/travelrmap-application.json'),
  },
  {
    id: 3,
    title: 'Book a Seat',
    description: 'Book a seat, manage your reservation and make payment with ease.',
    lottie: require('../../../assets/book-button.json'),
  },
];

const OnboardingScreen = () => {
  const carouselRef = useRef<any>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const { onPress: goToHome } = useLinkProps({ to: { screen: MAIN_SCREEN_NAME }, action: StackActions.replace(MAIN_SCREEN_NAME) });

  const goToNextSlide = () => {
    carouselRef.current?.scrollTo({ count: 1, animated: true });
  };

  const Footer = () => {
    return (
      <>
        <View style={{ height: height * 0.25, paddingHorizontal: 20 }}>
          {/* indicator container */}
          <View flexDirection="row" justifyContent="center">
            {/* render indicator */}
            {slides.map((_, index) => (
              <View key={index} style={[styles.indicator, currentSlideIndex === index && { backgroundColor: '#273C75' }]} />
            ))}
          </View>
          {/* Render buttons */}
          <View mt="16">
            {currentSlideIndex === 2 ? (
              <View style={{ height: 50 }}>
                <Button
                  size="lg"
                  fontFamily="AvertaStdBold"
                  backgroundColor="#273C75"
                  alignSelf="center"
                  w="full"
                  onPress={() => {
                    setHasOnBoarded();
                    goToHome();
                  }}
                >
                  Get Started
                </Button>
              </View>
            ) : (
              <View style={{ height: 50 }}>
                <Button
                  size="lg"
                  fontFamily="AvertaStdBold"
                  backgroundColor="#273C75"
                  alignSelf="center"
                  w="full"
                  onPress={goToNextSlide}
                >
                  Next
                </Button>
              </View>
            )}
          </View>
        </View>
      </>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <StatusBar />
      <Carousel
        ref={carouselRef}
        loop={false}
        data={slides}
        width={width}
        height={height * 0.75}
        pagingEnabled={true}
        onSnapToItem={index => setCurrentSlideIndex(index)}
        renderItem={({ item }) => <Slide item={item} currentSlideIndex={currentSlideIndex} ref={carouselRef} />}
      />
      <Footer />
    </SafeAreaView>
  );
};

export default OnboardingScreen;

import { FormControl, useTheme, View } from 'native-base';
import moment from 'moment';

import { COLORS, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl/types';

export interface DateTimePickerProps {
  label?: string;
  onDateChange?: (d: Date) => void;
  minimumDate?: Date;
}

export const DateTimePicker = ({ label, onDateChange, minimumDate, ...props }: DateTimePickerProps & IFormControlProps) => {
  const { colors } = useTheme();
  const onChange = (event: any) => {
    const { target } = event;

    if (onDateChange) {
      onDateChange(moment(target.value).toDate());
    }
  };

  return (
    <FormControl {...props} zIndex={-1}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      <View borderWidth={1} borderColor={colors.muted[300]} borderRadius={4} height={THEME_INPUT_HEIGHT}>
        <input
          type="date"
          onChange={onChange}
          min={minimumDate ? moment(minimumDate).format('YYYY-MM-DD') : undefined}
          style={{
            height: '100%',
            borderRadius: 4,
            paddingLeft: 12,
            paddingRight: 12,
            backgroundColor: COLORS.grey,
            borderWidth: 0,
          }}
        />
      </View>
    </FormControl>
  );
};

import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { BOOKING_ACCESS, BOOKING_INFORMATION } from 'src/navigation/constants';
import { BookingStackParamList } from 'src/types/root.type';
import { BookingProvider } from 'src/providers/booking-provider/BookingProvider';
import { Booking } from 'src/screens/bookings/Bookings';
import { BookingInformation } from 'src/screens/bookings/BookingInformation';

const Stack = createStackNavigator<BookingStackParamList>();

const screenOptions: StackNavigationOptions = {
  headerShown: true,
  header: NavigationHeader,
};

export const BookingStack = () => {
  const initialRouteName = BOOKING_ACCESS;

  return (
    <BookingProvider>
      <Stack.Navigator screenOptions={screenOptions} initialRouteName={initialRouteName}>
        <Stack.Screen name={BOOKING_ACCESS} component={Booking} options={{ title: 'Bookings' }} />
        <Stack.Screen name={BOOKING_INFORMATION} component={BookingInformation} />
      </Stack.Navigator>
    </BookingProvider>
  );
};

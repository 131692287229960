import { Box, Divider, HStack, Icon, Link, Text, VStack, Image, Pressable, Stack } from 'native-base';
import { Entypo, MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';

const Links_Company = ['Who We Are', 'Contact', 'Carrers'];
const Links_Business = ['Hire a Bus', 'Advertise with us'];
const Links_ForYou = [
  { title: 'Book a Seat', href: '/' },
  { title: 'Manage my booking', href: '/manage-booking' },
  { title: 'Online Check-in', href: '/check-in' },
];
const Links_Help = ['Privacy', 'Terms'];

export default function Footer() {
  return (
    <Box bg="coolGray.100" w="100%" py="5" px="8" alignItems="center">
      <VStack maxW="1100">
        <Stack
          mb={{ base: 5, md: 8 }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Image
            source={require('src/screens/landing/images/logo.png')}
            alt="Alternate Text"
            style={{ width: 94, height: 50 }}
            resizeMode="contain"
          />
          <HStack alignItems="center" space="4">
            <Pressable>
              <HStack space="1" py="1" px="1" alignItems="center" borderWidth="1" borderColor="coolGray.400" borderRadius="4">
                <Image
                  source={{
                    uri: 'https://flagcdn.com/w80/ng.png',
                  }}
                  alt="Nigerian flag"
                  size="5"
                  resizeMode="contain"
                  mr="2"
                  ml="1"
                />
                <Text fontWeight="normal" fontSize="lg">
                  Nigeria
                </Text>

                <Icon as={<MaterialCommunityIcons name="chevron-down" />} size="5" color="coolGray.900" />
              </HStack>
            </Pressable>
            <Pressable>
              <HStack space="1" py="1" px="1" alignItems="center" borderWidth="1" borderColor="coolGray.400" borderRadius="4">
                <Icon as={<SimpleLineIcons name="globe" />} size="4" color="coolGray.900" mr="1" ml="1" />
                <Text fontWeight="normal" fontSize="lg">
                  English
                </Text>

                <Icon as={<MaterialCommunityIcons name="chevron-down" />} size="5" color="coolGray.900" />
              </HStack>
            </Pressable>
          </HStack>
        </Stack>
        <HStack justifyContent="space-between" mr="16" flexDirection={{ base: 'column', lg: 'row' }}>
          <VStack mb="4" space={2}>
            <Text mb={2} fontSize="sm" fontWeight="medium" letterSpacing="2xl">
              COMPANY
            </Text>
            {Links_Company.map((item, index) => {
              return (
                <Link
                  key={index}
                  href="#"
                  isUnderlined={false}
                  _text={{
                    color: 'coolGray.500',
                    fontSize: 'sm',
                    fontWeight: 'light',
                  }}
                  _hover={{
                    _text: {
                      color: 'grayZomato.700',
                    },
                  }}
                >
                  {item}
                </Link>
              );
            })}
          </VStack>

          <VStack>
            <VStack mb="4" space={2}>
              <Text mb={2} fontSize="sm" fontWeight="medium" letterSpacing="2xl">
                FOR YOU
              </Text>
              {Links_ForYou.map((item, index) => {
                return (
                  <Link
                    key={index}
                    href={item.href}
                    isUnderlined={false}
                    _text={{
                      color: 'coolGray.500',
                      fontSize: 'sm',
                      fontWeight: 'light',
                    }}
                    _hover={{
                      _text: {
                        color: 'grayZomato.700',
                      },
                    }}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </VStack>
          </VStack>
          <VStack mb="4" space={2}>
            <Text mb={2} fontSize="sm" fontWeight="medium" letterSpacing="2xl">
              FOR BUSINESS
            </Text>
            {Links_Business.map((item, index) => {
              return (
                <Link
                  key={index}
                  href="#"
                  isUnderlined={false}
                  _text={{
                    color: 'coolGray.500',
                    fontSize: 'sm',
                    fontWeight: 'light',
                  }}
                  _hover={{
                    _text: {
                      color: 'grayZomato.700',
                    },
                  }}
                >
                  {item}
                </Link>
              );
            })}
          </VStack>
          <VStack mb="4" space={2}>
            <Text mb={2} fontSize="sm" fontWeight="medium" letterSpacing="2xl">
              HELP
            </Text>
            {Links_Help.map((item, index) => {
              return (
                <Link
                  key={index}
                  href="#"
                  isUnderlined={false}
                  _text={{
                    color: 'coolGray.500',
                    fontSize: 'sm',
                    fontWeight: 'light',
                  }}
                  _hover={{
                    _text: {
                      color: 'grayZomato.700',
                    },
                  }}
                >
                  {item}
                </Link>
              );
            })}
          </VStack>
          <VStack mb="4" space={2} pr="8">
            <Text mb={2} fontSize="sm" fontWeight="medium" letterSpacing="2xl">
              SOCIAL LINKS
            </Text>
            <HStack space="2">
              <Pressable>
                <Icon size="6" as={Entypo} name="facebook-with-circle" color="coolGray.900" />
              </Pressable>
              <Pressable>
                <Icon size="6" as={Entypo} name="twitter-with-circle" color="coolGray.900" />
              </Pressable>
              <Pressable>
                <Icon size="6" as={Entypo} name="instagram-with-circle" color="coolGray.900" />
              </Pressable>
            </HStack>
            <VStack mt="2" space={{ base: '1', lg: 0 }}>
              <Pressable>
                <Image
                  source={{
                    uri: 'https://b.zmtcdn.com/data/webuikit/9f0c85a5e33adb783fa0aef667075f9e1556003622.png',
                  }}
                  alt="Alternate Text"
                  resizeMode="contain"
                  w={{ base: 40, lg: '100%' }}
                  h={12}
                />
              </Pressable>
              <Pressable>
                <Image
                  source={{
                    uri: 'https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png',
                  }}
                  alt="Alternate Text"
                  resizeMode="contain"
                  w={{ base: 40, lg: '100%' }}
                  h={12}
                />
              </Pressable>
            </VStack>
          </VStack>
        </HStack>
        <Divider mt="8" width="100%" bg="coolGray.400" />
        <HStack mt="6" mb="3">
          <Text fontSize="xs" fontWeight="light" lineHeight="16" color="grayZomato.600">
            By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All
            trademarks are properties of their respective owners. 2008-2023 © Okeyson Investment Services™ Ltd. All rights reserved.
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
}

import AntDesign from '@expo/vector-icons/AntDesign';
import { Center, useTheme } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { BodyText } from 'src/components/typography/BodyText';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';

export const Notifications = () => {
  const { colors } = useTheme();
  return (
    <ContentContainer statusBarStyle="dark-content" allWhiteBackground>
      <Center flex={1}>
        <AntDesign name="inbox" size={100} color={colors.primary[PRIMARY_COLOR_NUMBER]} />
        <BodyText text="No notifications at this time" />
      </Center>
    </ContentContainer>
  );
};

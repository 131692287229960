import React from 'react';

import { Box, Divider, HStack, Icon, VStack, useTheme } from 'native-base';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { getCurrencyText } from 'src/utils/currency';
import { BodyText } from 'src/components/typography/BodyText';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { TripIllustrationImage } from 'src/assets/images/svg/TripIllustration';
import { FontAwesome } from '@expo/vector-icons';
import { capitalize } from 'src/utils/string';

export const ReservationHeader: React.FC = () => {
  const { departureSchedule, search, availableSchedules } = useReservation();
  const passengers = (search?.adult ?? 0) + (search?.child ?? 0) + (search?.infant ?? 0);
  const totalFare = departureSchedule ? departureSchedule.totalFare * passengers : 0;
  const { colors } = useTheme();

  return (
    <Box width="100%" backgroundColor={colors.primary[PRIMARY_COLOR_NUMBER]} p={5} marginBottom={2}>
      <HStack justifyContent={{ md: 'center', lg: 'space-around' }} display={{ base: 'none', md: 'auto' }}>
        <HStack divider={<Divider />} space={5}>
          <HStack space={3} alignItems="center" marginBottom={2}>
            <VStack alignItems="center" space={2}>
              <BodyText text={capitalize(search?.terminal ?? '')} bold color={colors.white} />
              <BodyText text={capitalize(search?.terminalState ?? '')} fontSize={8} maxWidth={12} color={colors.white} />
            </VStack>
            <TripIllustrationImage height={15} width={200} fill={colors.white} />
            <VStack alignItems="center" space={2}>
              <BodyText text={capitalize(search?.destination ?? '')} bold color={colors.white} />
              <BodyText text={capitalize(search?.destinationState ?? '')} fontSize={8} maxWidth={12} color={colors.white} />
            </VStack>
          </HStack>
          <VStack>
            <BodyText text="Departure" color={colors.white} />
            <BodyText text={search?.date ?? ''} color={colors.white} />
          </VStack>

          <VStack>
            <BodyText text="Passenger(s)" color={colors.white} />
            <HStack space={2} alignItems={'center'}>
              <BodyText text={passengers.toString()} color={colors.white} />
              <Icon as={FontAwesome} name="user" color={colors.white} />
            </HStack>
          </VStack>
        </HStack>
        <VStack display={{ md: 'none', lg: 'auto', xl: 'auto' }}>
          <BodyText text={`Est. Ticket price for ${passengers} passenger(s)`} alignSelf="end" color={colors.white} />
          <SubSubHeader text={getCurrencyText(totalFare)} alignSelf="end" bold color={colors.white} />
        </VStack>
      </HStack>

      {/* Mobile and small screen view */}
      <VStack display={{ xl: 'none', md: 'none' }} space={4}>
        <HStack space={3} alignItems="center" marginBottom={2} justifyContent="center">
          <VStack alignItems="center" space={2} textAlign="center">
            <BodyText text={capitalize(search?.terminal ?? '')} bold color={colors.white} ellipsizeMode={'tail'} width={20} />
            <BodyText text={capitalize(search?.terminalState ?? '')} fontSize={8} maxWidth={12} color={colors.white} />
          </VStack>
          <TripIllustrationImage height={15} width={200} fill={colors.white} />
          <VStack alignItems="center" justifyContent={'center'} space={2} textAlign="center">
            <BodyText text={capitalize(search?.destination ?? '')} bold color={colors.white} width={20} />
            <BodyText text={capitalize(search?.destinationState ?? '')} fontSize={8} maxWidth={12} color={colors.white} />
          </VStack>
        </HStack>

        <HStack divider={<Divider />} space={2} justifyContent="center">
          <BodyText text={search?.date ?? ''} color={colors.white} />

          <HStack space={2} alignItems={'center'}>
            <BodyText text={passengers.toString()} color={colors.white} />
            <BodyText text="Passenger(s)" color={colors.white} />
          </HStack>

          <HStack space={2} alignItems={'center'}>
            <BodyText text={availableSchedules?.departures.length.toString() ?? ''} color={colors.white} />
            <BodyText text="Trip(s)" color={colors.white} />
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

import { Avatar, Box, HStack, Pressable, VStack, useTheme } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { CreatePassengersInput } from 'src/graphql/generated/types';
import { TripSeatMapQuery_tripSeatMap_Seat } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { getInitials } from 'src/utils/string';

interface PassengerTabProps {
  passengers: Partial<CreatePassengersInput>[];
  setActiveIndex?: (index: number) => void;
  activeIndex: number;
  seats: TripSeatMapQuery_tripSeatMap_Seat[];
}

interface PassengerTabItemProps {
  isActive?: boolean;
  name: string;
  seat?: string;
  onPress: () => void;
}

const PassengerTabItem: FC<PassengerTabItemProps> = ({ isActive, name, seat, onPress }) => {
  return (
    <Pressable flex={1} onPress={onPress}>
      {({ isHovered }) => {
        const { colors } = useTheme();
        let bg = isHovered ? colors.coolGray['100'] : colors.white;
        bg = isActive ? colors.primary[PRIMARY_COLOR_NUMBER] : bg;
        return (
          <Box padding={3} backgroundColor={bg} borderRadius={4}>
            <HStack space={2} justifyContent={'center'}>
              <Avatar
                bg={isActive ? bg : colors.white}
                _text={{ color: isActive ? colors.white : 'primary.400' }}
                borderColor={isActive ? colors.white : 'primary.500'}
                borderWidth={1}
              >
                {getInitials(name)}
              </Avatar>
              <VStack justifyContent="space-between">
                <BodyText text={name} bold color={isActive ? colors.white : undefined} />
                <HStack alignItems="center" space={2}>
                  <BodyText text="Seat:" color={isActive ? colors.white : undefined} bold />
                  <SmallLabel text={seat ?? 'No seat selected'} color={isActive ? colors.white : undefined} />
                </HStack>
              </VStack>
            </HStack>
          </Box>
        );
      }}
    </Pressable>
  );
};

export const SelectSeatPassengerTab: FC<PassengerTabProps> = ({ passengers, setActiveIndex, activeIndex, seats }) => {
  return (
    <Box flexDirection="row" borderWidth={1} borderBottomColor={'primary.300'} borderRadius={4}>
      {passengers.map((p, i) => {
        const seatId = passengers[i].seatId;
        const seat = seatId ? seats.find(s => s.id === seatId.toString())?.number : undefined;
        return (
          <PassengerTabItem
            key={`${i}_passenger`}
            name={p.fullName ?? ''}
            seat={seat}
            onPress={() => setActiveIndex && setActiveIndex(i)}
            isActive={activeIndex === i}
          />
        );
      })}
    </Box>
  );
};

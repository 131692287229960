import moment from 'moment';
import { Alert, Box, HStack, Heading, VStack } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SubHeader } from 'src/components/typography/SubHeader';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';

export interface BookingNoteProps {
  expiryDate: string;
}

export const BookingNote: FC<BookingNoteProps> = ({ expiryDate }) => {
  const dateTime = moment(expiryDate).format('MMMM Do YYYY, h:mm:ss a');
  return (
    <Alert shadow={2} w="100%" colorScheme="info" marginBottom={5}>
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <SubSubHeader text="Important Notice!" />
          </HStack>
        </HStack>
        <Box
          pl="6"
          _text={{
            color: 'coolGray.600',
          }}
        >
          You have until <BodyText bold text={dateTime} /> to confirm your reservation and purchase your tickets.
        </Box>
      </VStack>
    </Alert>
  );
};

import { ContentContainer } from 'src/components/container/ContentContainer';
import { PassengerInformationForm } from 'src/screens/book-seat/components/PassengerInformationForm';
import { useNavigation } from '@react-navigation/native';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { getPassengerTypeOrder } from 'src/screens/book-seat/ReservationUtils';
import { ReservationHeader } from './components/ReservationHeader';
import { PageHeader } from 'src/components/typography/PageHeader';
import { Center, Flex, Icon, useTheme } from 'native-base';
import { Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { isWeb } from 'src/utils/environment.utils';

export const CreateOrEditPassengertInformation: React.FC = () => {
  const { sizes } = useTheme();
  const { passengers, search, addPassenger, updatePassenger, editablePassengerIndex, setEditablePassengerIndex } = useReservation();
  const navigation = useNavigation();

  const editablePassenger = editablePassengerIndex !== undefined ? passengers![editablePassengerIndex] : undefined;

  const onPassengerActionCompleted = () => {
    setEditablePassengerIndex(undefined);
    navigation.goBack();
  };

  const passengerTypeList = getPassengerTypeOrder(search?.adult ?? 0, search?.child, search?.infant);
  const type = passengerTypeList[passengers?.length ?? 0];

  return (
    <ContentContainer
      statusBarStyle="dark-content"
      allWhiteBackground={isWeb() ? false : true}
      showFooter={false}
      header={<ReservationHeader />}
      headerFullWidth={true}
    >
      {isWeb() && (
        <>
          <Flex alignItems="flex-end" marginTop={2}>
            <Pressable onPress={() => navigation.canGoBack() && navigation.goBack()}>
              <Icon as={MaterialCommunityIcons} name="close" size={{ base: sizes['1.5'], md: sizes[2], lg: sizes[3] }} />
            </Pressable>
          </Flex>
          <PageHeader
            text={editablePassenger ? `Passenger (${editablePassenger?.type})` : 'Add Passenger'}
            fontSize={'2xl'}
            marginTop={2}
            marginBottom={2}
          />
        </>
      )}
      <Center>
        <PassengerInformationForm
          addPassenger={addPassenger}
          updatePassenger={updatePassenger}
          onComplete={onPassengerActionCompleted}
          editablePassenger={editablePassenger}
          editablePassengerIndex={editablePassengerIndex}
          type={type}
          isVisible={true}
        />
      </Center>
    </ContentContainer>
  );
};

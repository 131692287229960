import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';

import {
  AVAILABLITY_SCREEN_NAME,
  BOOK_SEAT_SCREEN_NAME,
  CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME,
  INBOUND_SCREEN_NAME,
  PASSENGER_INFORMATION_SCREEN_NAME,
  PAYMENT_CONFIRMATION_SCREEN_NAME,
  RESERVATION_CONFIRMED_SCREEN_NAME,
  SELECT_SEAT_NAME,
} from 'src/navigation/constants';
import BookSeatScreen from 'src/screens/book-seat/BookSeat';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { PassengertInformation } from 'src/screens/book-seat/PassengerInformation';
import { PaymentConfirmation } from 'src/screens/book-seat/PaymentConfirmation';
import { Availability } from 'src/screens/book-seat/Availability';
import { ReservationStackParamList } from 'src/types/root.type';
import { ReservationSuccess } from 'src/screens/book-seat/ReservationSuccess';
import { SelectSeat } from 'src/screens/book-seat/SelectSeat';
import { CreateOrEditPassengertInformation } from 'src/screens/book-seat/CreateOrEditPassengerInformation';

const ReservationStack = () => {
  const Stack = createStackNavigator<ReservationStackParamList>();

  const screenOptions: StackNavigationOptions = {
    title: BOOK_SEAT_SCREEN_NAME,
    headerShown: true,
    header: NavigationHeader,
  };

  return (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen name={BOOK_SEAT_SCREEN_NAME} component={BookSeatScreen} />
      <Stack.Screen name={AVAILABLITY_SCREEN_NAME} component={Availability} />
      <Stack.Screen name={INBOUND_SCREEN_NAME} component={Availability} />
      <Stack.Screen name={PASSENGER_INFORMATION_SCREEN_NAME} component={PassengertInformation} />
      <Stack.Screen name={PAYMENT_CONFIRMATION_SCREEN_NAME} component={PaymentConfirmation} />
      <Stack.Screen name={CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME} component={CreateOrEditPassengertInformation} />
      <Stack.Screen name={RESERVATION_CONFIRMED_SCREEN_NAME} component={ReservationSuccess} />
      <Stack.Screen name={SELECT_SEAT_NAME} component={SelectSeat} />
    </Stack.Navigator>
  );
};

export default ReservationStack;

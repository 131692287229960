import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { CheckInPassengerMutation, CheckInPassengerMutationVariables } from './__generated__/checkin-passenger.generated';
import { GET_RESERVATION_BY_SURNAME_QUERY } from '../queries/get-reservation-by-surname';

const CHECKIN_PASSENGER_MUTATION = gql`
  mutation CheckInPassenger($passengerId: Float!, $seatId: Float!) {
    checkInPassenger(passengerId: $passengerId, seatId: $seatId) {
      id
      seatId
    }
  }
`;

export const useCheckinPassengerMutation = (
  reservationId: number,
  surname: string
): MutationTuple<CheckInPassengerMutation, CheckInPassengerMutationVariables> =>
  useMutationErrorNotification<CheckInPassengerMutation, CheckInPassengerMutationVariables>(CHECKIN_PASSENGER_MUTATION, {
    refetchQueries: [{ query: GET_RESERVATION_BY_SURNAME_QUERY, variables: { reservationId, surname } }],
    awaitRefetchQueries: true,
  });

import { Actionsheet, Avatar, Box, HStack } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';

interface ReservationPaymentProps {
  onProviderSelected: (provider: string) => void;
}

export const PaymentProviderItem = ({ imageUrl, title }: { imageUrl: string; title: string }) => {
  return (
    <Box px="2" py="2">
      <HStack space={[2, 3]} justifyContent="space-between" alignItems="center">
        <Avatar
          size="48px"
          backgroundColor={'transparent'}
          _image={{ resizeMode: 'contain' }}
          source={{
            uri: imageUrl,
            height: 48,
          }}
        />
        <BodyText color="coolGray.800" bold text={title} fontSize={'xl'} />
      </HStack>
    </Box>
  );
};

export const ReservationPayment: FC<ReservationPaymentProps> = ({ onProviderSelected }) => {
  return (
    <Box width={'100%'}>
      {/* <Actionsheet.Item width={'100%'} onPress={() => onProviderSelected('flutterwave')}>
        <PaymentProviderItem title="Flutterwave" imageUrl="https://asset.brandfetch.io/iddYbQIdlK/idLRUXusyc.png" />
      </Actionsheet.Item> */}

      <Actionsheet.Item width={'100%'} onPress={() => onProviderSelected('paystack')}>
        <PaymentProviderItem
          title="Paystack"
          imageUrl="https://ors.ams3.cdn.digitaloceanspaces.com/public/idoIEO_8K-_1723823236287.jpeg"
        />
      </Actionsheet.Item>

      <Actionsheet.Item width={'100%'} onPress={() => onProviderSelected('paystack')}>
        <PaymentProviderItem
          title="Paystack"
          imageUrl="https://ors.ams3.cdn.digitaloceanspaces.com/public/idoIEO_8K-_1723823236287.jpeg"
        />
      </Actionsheet.Item>
    </Box>
  );
};

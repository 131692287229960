import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { CHECK_IN_INFORMATION_SCREEN_NAME, CHECK_IN_SCREEN_NAME, CHECK_IN_SEAT_SCREEN_NAME } from 'src/navigation/constants';
import { CheckinStackParamList } from 'src/types/root.type';
import { BookingProvider } from 'src/providers/booking-provider/BookingProvider';
import { CheckIn } from 'src/screens/checkin/CheckIn';
import { CheckinInformation } from 'src/screens/checkin/CheckinInformation';
import { CheckinSeat } from 'src/screens/checkin/CheckinSeat';

const Stack = createStackNavigator<CheckinStackParamList>();

const screenOptions: StackNavigationOptions = {
  headerShown: true,
  header: NavigationHeader,
};

export const CheckinStack = () => {
  const initialRouteName = CHECK_IN_SCREEN_NAME;

  return (
    <BookingProvider>
      <Stack.Navigator screenOptions={screenOptions} initialRouteName={initialRouteName}>
        <Stack.Screen name={CHECK_IN_SCREEN_NAME} component={CheckIn} options={{ title: 'Check In' }} />
        <Stack.Screen name={CHECK_IN_INFORMATION_SCREEN_NAME} component={CheckinInformation} />
        <Stack.Screen name={CHECK_IN_SEAT_SCREEN_NAME} component={CheckinSeat} />
      </Stack.Navigator>
    </BookingProvider>
  );
};

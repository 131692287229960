export const fontSizeOverride = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 32,
  '4xl': 36,
  '5xl': 48,
  '6xl': 60,
};

import { Box, HStack } from 'native-base';
import { BodyText } from 'src/components/typography/BodyText';

export const UpcomingTrips = () => {
  return (
    <HStack padding={4} marginTop={6}>
      <BodyText text="Upcoming trip" />
      <Box></Box>
    </HStack>
  );
};

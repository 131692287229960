import React, { ReactNode } from 'react';
import { Platform, SafeAreaView, StatusBar } from 'react-native';
import { Flex, useTheme, View } from 'native-base';

import { getPlatformMaxScreenWidth } from 'src/utils/display/max-width';
import { LoadingState } from '../loading-state/LoadingState';
import Footer from '../layout/Footer';
import { isWeb } from 'src/utils/environment.utils';
import { PageHeader } from '../typography/PageHeader';
import { ContentContainerBaseProps } from './ContentContainer';

export interface ContentContainerProps extends ContentContainerBaseProps {
  header?: ReactNode;
  title?: string;
  headerFullWidth?: boolean;
  contentFullWidth?: boolean;
  allWhiteBackground?: boolean;
  sticky?: () => ReactNode;
  showFooter?: boolean;
  backgroundColor?: string;
}

export const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  header,
  headerFullWidth = false,
  contentFullWidth = false,
  allWhiteBackground = true,
  padContent = true,
  showFooter = true,
  loading,
  sticky,
  title,
  statusBarColor,
  statusBarStyle,
}) => {
  const { colors, space } = useTheme();

  const maxWidth = contentFullWidth ? '100%' : getPlatformMaxScreenWidth();
  const padding = contentFullWidth ? 0 : 10;
  const contentPadding = Platform.OS === 'web' && contentFullWidth ? space[0] : space[1];

  return (
    // Any content wrapped by <ContentContainer> will be scrollable by default
    <SafeAreaView style={{ height: '100%', backgroundColor: allWhiteBackground ? colors.white : '#fbfbfb' }}>
      <Flex backgroundColor={allWhiteBackground ? colors.white : '#fbfbfb'}>
        <StatusBar barStyle={statusBarStyle ?? 'default'} backgroundColor={statusBarColor} />
        {/* This <View> is necessary, for the cases where we want to have the page content restricted to a max width but the Header strech across the screen */}
        <View
          flex={1}
          minHeight={isWeb() ? undefined : undefined}
          width="100%"
          alignItems="center"
          pt={headerFullWidth && header ? 0 : padding}
          p={headerFullWidth && header ? 0 : padding}
        >
          {/* When we want a full screen-width Header it needs to be rendered outside the width restricted content */}
          {headerFullWidth && header ? header : null}

          {/* This <View> is responsible for restricting the content width to a max width */}
          <View maxWidth={maxWidth} width="100%" height="100%">
            {/* This <View> is responsible for centering the actual content */}
            <View height="100%" alignItems="center" pl={contentPadding} pr={contentPadding}>
              {!headerFullWidth && header ? header : null}
              <View width="100%" padding={Platform.OS !== 'web' && padContent ? space[1] : space[0]}>
                {title && <PageHeader text={title} />}
                {loading && <LoadingState />}
                {!loading && children}
              </View>
            </View>
          </View>
        </View>
        {sticky && sticky()}
        {Platform.OS === 'web' && showFooter && <Footer />}
      </Flex>
    </SafeAreaView>
  );
};

export const capitalizeWord = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export const capitalize = (str: string): string => {
  const splitString = str.split(' ');
  return splitString.map(str => capitalizeWord(str)).join(' ');
};

export const getInitials = (str: string) => {
  const splitString = str.split(' ');
  const listLenght = splitString.length;

  if (listLenght === 1) {
    return splitString[0].charAt(0).toLocaleUpperCase();
  } else if (splitString.length > 1) {
    return `${splitString[0].charAt(0)}.${splitString[listLenght - 1].charAt(0)}`.toUpperCase();
  }
};

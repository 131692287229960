import { Button, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Input } from 'src/components/input/Input';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { Alert } from 'src/components/alerts/Alert';
import { useLinkProps, StackActions } from '@react-navigation/native';
import { CHECK_IN_INFORMATION_SCREEN_NAME } from 'src/navigation/constants';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { PageHeader } from 'src/components/typography/PageHeader';
import { isWeb } from 'src/utils/environment.utils';
import { BodyText } from 'src/components/typography/BodyText';

export const CheckIn = () => {
  const { onPress: goToCheckinInformation } = useLinkProps({
    to: { screen: CHECK_IN_INFORMATION_SCREEN_NAME },
    action: StackActions.replace(CHECK_IN_INFORMATION_SCREEN_NAME),
  });
  const { getReservation, booking, loading, bookingError } = useBooking();
  const [reference, setReference] = useState<string>();
  const [surname, setSurname] = useState<string>();

  useEffect(() => {
    if (booking?.getReservationBySurname) {
      goToCheckinInformation();
    }
  }, [booking]);

  const onSubmit = async () => {
    if (reference && surname) {
      await getReservation(reference.trim(), surname.trim());
    }
  };

  const isDisabled = !reference || !surname || loading;

  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      {(booking?.getReservationBySurname === null || bookingError) && (
        <Alert
          type="error"
          description={booking?.getReservationBySurname === null ? 'Booking does not exist' : bookingError?.message ?? ''}
        />
      )}
      <VStack alignItems="center" space={3} marginBottom={10}>
        <PageHeader text="Online Check-in" />
        <BodyText
          text="Check in online, select your seat and save time at our terminals. You can check‑in online hours ahead of your trip."
          maxWidth={500}
          textAlign="center"
        />
      </VStack>
      <VStack space={3} maxWidth={isWeb() ? 500 : undefined} alignSelf="center" width={'100%'}>
        <Input
          label="Booking reference"
          isRequired
          placeholder="Enter booking reference"
          isDisabled={loading}
          onChangeText={setReference}
        />
        <Input label="Surname" isRequired placeholder="Enter surname for the booking" isDisabled={loading} onChangeText={setSurname} />
        <Button size="lg" marginTop={6} onPress={onSubmit} isDisabled={isDisabled} disabled={isDisabled} isLoading={loading}>
          Get Booking
        </Button>
      </VStack>
    </ContentContainer>
  );
};

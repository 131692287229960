export const ONBOARDING_SCREEN_NAME = 'Onboarding';
export const LANDING_SCREEN_NAME = 'Landing';
export const HOME_SCREEN_NAME = 'Home';
export const CHECK_IN_SCREEN_NAME = 'Check-In';
export const CHECK_IN_INFORMATION_SCREEN_NAME = 'Check-In Information';
export const CHECK_IN_SEAT_SCREEN_NAME = 'Check-In Seat';
export const CHECK_IN_STACK_NAME = 'Check-In Stack';
export const SETTING_SCREEN_NAME = 'More';
export const MAIN_SCREEN_NAME = 'MainScreen';
export const GUEST_SCREEN_NAME = 'Guest Confirm';
export const BOOK_SEAT_SCREEN_NAME = 'Book a Seat';
export const PASSENGER_INFORMATION_SCREEN_NAME = 'Passenger Information';
export const CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME = 'Passenger';
export const AVAILABLITY_SCREEN_NAME = 'Availability';
export const INBOUND_SCREEN_NAME = 'Select Inbound';
export const RESERVATION_STACK_NAME = 'Reservation';
export const NOTIFICATION_SCREEN_NAME = 'Notifications';
export const SELECT_SEAT_NAME = 'Select Seat';
export const PAYMENT_CONFIRMATION_SCREEN_NAME = 'Payment Confirmation';
export const RESERVATION_CONFIRMED_SCREEN_NAME = 'Reservation Confirmed';
export const BOOKING_INFORMATION = 'Booking Information';
export const BOOKING_ACCESS = 'Booking';
export const BOOKING_STACK_NAME = 'Bookings';
export const MANAGE_BOOKING_STACK_NAME = 'Manage Bookings';

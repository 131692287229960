import { Box, Button, ScrollView, VStack, View, useToast } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { LoadingState } from 'src/components/loading-state/LoadingState';
import { TripSeatMapQuery_tripSeatMap_Seat, useGetTripSeatMapQuery } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { SeatSelector } from '../book-seat/components/SeatSelector';
import { getSeatMapGrid } from '../book-seat/ReservationUtils';
import { useEffect, useMemo, useState } from 'react';
import { useLinkProps, useRoute } from '@react-navigation/native';
import { CheckinSeatParams } from 'src/types/root.type';
import { useCheckinPassengerMutation } from 'src/graphql/ticketing_api/mutations/checkin-passenger';
import { CHECK_IN_INFORMATION_SCREEN_NAME } from 'src/navigation/constants';
import { SelectSeatPassengerTab } from '../book-seat/components/SelectSeatPassengerTab';

export const CheckinSeat = () => {
  const { booking, loading, surname } = useBooking();
  const { params } = useRoute();
  if (
    !booking?.getReservationBySurname ||
    !(params as CheckinSeatParams)?.passengerNo ||
    !(params as CheckinSeatParams)?.passengerName ||
    loading
  )
    return <LoadingState />;

  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      <CheckinSeatMap
        tripId={booking.getReservationBySurname.trip.id}
        passengerId={(params as CheckinSeatParams).passengerNo}
        passengerName={(params as CheckinSeatParams).passengerName}
        reservationId={parseInt(booking.getReservationBySurname.id)}
        surname={surname ?? ''}
      />
    </ContentContainer>
  );
};

export const CheckinSeatMap = ({
  tripId,
  passengerId,
  passengerName,
  reservationId,
  surname,
}: {
  tripId: string;
  passengerId: string;
  passengerName: string;
  reservationId: number;
  surname: string;
}) => {
  const { data, loading } = useGetTripSeatMapQuery(tripId);
  const { show } = useToast();
  const [checkinPassenger, { loading: passengerCheckinLoading, data: checkedInPassenger, error }] = useCheckinPassengerMutation(
    reservationId,
    surname
  );
  const [selectedSeatId, setSelectedSeatId] = useState<number>();
  const { onPress: goToCheckinInfo } = useLinkProps({
    to: { screen: CHECK_IN_INFORMATION_SCREEN_NAME },
  });

  useEffect(() => {
    const completeCheckingIn = async () => {
      if (checkedInPassenger?.checkInPassenger.id) {
        show({
          render: () => {
            return (
              <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5} color={'white'}>
                Checkin successful
              </Box>
            );
          },
        });
        goToCheckinInfo();
      }
    };
    completeCheckingIn();
  }, [checkedInPassenger?.checkInPassenger]);

  const selectedSeatIds = useMemo(() => (selectedSeatId ? [selectedSeatId] : []), [selectedSeatId]);

  const onSeatSelected = (seat: TripSeatMapQuery_tripSeatMap_Seat) => {
    setSelectedSeatId(parseInt(seat.id));
  };

  const onCheckin = async () => {
    if (selectedSeatId === 0) return;
    await checkinPassenger({
      variables: {
        passengerId: parseInt(passengerId),
        seatId: selectedSeatId,
      },
    });
  };

  if (loading || !data) return <LoadingState />;

  const isCheckinBtnDisabled = !selectedSeatId || passengerCheckinLoading;
  const grid = getSeatMapGrid(data);
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View>
        <SelectSeatPassengerTab
          seats={data.tripSeatMap.seats}
          passengers={[{ seatId: selectedSeatId, fullName: passengerName }]}
          activeIndex={0}
        />
      </View>
      <VStack space={4}>
        <SeatSelector seatMap={grid} onSeatSelected={onSeatSelected} selectedSeatIds={selectedSeatIds} />
        <Button isDisabled={isCheckinBtnDisabled} style={{ marginTop: 20 }} isLoading={passengerCheckinLoading} onPress={onCheckin}>
          Check In
        </Button>
      </VStack>
    </ScrollView>
  );
};

import React, { ReactNode } from 'react';

import propTypes from 'prop-types';
import { Svg } from 'react-native-svg';

export const SMALL_ICON_SIZE = {
  width: 20,
  height: 20,
};

export interface BaseSvgProps {
  viewBox: {
    width: number;
    height: number;
  };
  fill?: string;
  children: ReactNode | ReactNode[];
  width: number;
  height: number;
}

const BaseSvg: React.FC<BaseSvgProps> = ({ viewBox, children, width, height, ...props }) => (
  <Svg
    viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
    width={width ?? SMALL_ICON_SIZE.width}
    height={height ?? SMALL_ICON_SIZE.height}
    {...props}
  >
    {children}
  </Svg>
);

export default BaseSvg;

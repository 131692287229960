import { useEffect, useState } from 'react';
import { Button, View } from 'native-base';

import { ContentContainer } from 'src/components/container/ContentContainer';
import { DateTimePicker } from 'src/components/date-picker/DatePicker';
import { BodyText } from 'src/components/typography/BodyText';
import { PassengerCounter, PassengerSet } from './components/PassengerCounter';
import { SelectItem } from 'src/components/select/Select';
import { useLinkProps } from '@react-navigation/native';
import { AVAILABLITY_SCREEN_NAME } from 'src/navigation/constants';
import { useGetTerminalsQuery } from 'src/graphql/ticketing_api/queries/get-terminal';
import { useFindTerminalLazyQuery } from 'src/graphql/ticketing_api/queries/find-terminal';
import moment from 'moment';
import { useGetAvailablityLazyQuery } from 'src/graphql/ticketing_api/queries/get-availability';
import { ItenaryInput } from 'src/graphql/generated/types';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { SingleValue, MultiValue } from 'react-select';
import { SearchableSelect } from 'src/components/select/SearchableSelect';
import { capitalize } from 'src/utils/string';
import { removeItem } from 'src/utils/storage/store';
import { StorageKeysEnum } from 'src/constants/storage';

const BookSeatScreen = () => {
  const { setAvailableSchedules, setSearch, clearCache } = useReservation();
  const [adult, setAdult] = useState<number>(1);
  const [selectedTerminal, setSelectedTerminal] = useState<string>();
  const [selectedRoute, setSelectedRoute] = useState<string>();
  const [child, setChild] = useState<number>(0);
  const [infant, setInfant] = useState<number>(0);
  const [departureDate, setDepartureDate] = useState<Date>();
  const [arrivalDate] = useState<Date>();
  const { data: terminalsList, loading } = useGetTerminalsQuery();
  const [findTerminal, { data: terminal, loading: loadingTerminal }] = useFindTerminalLazyQuery();
  const { onPress: goToAvailablity } = useLinkProps({
    to: {
      screen: AVAILABLITY_SCREEN_NAME,
      params: {
        adult: String(adult),
        child: String(child),
        infant: String(infant),
        routeId: selectedRoute,
        terminalId: selectedTerminal,
        departureDate: moment(departureDate).format('YYYY-MM-DD'),
      },
    },
  });
  const [getAvailableShcedules, { loading: loadingResults }] = useGetAvailablityLazyQuery();

  const terminals =
    terminalsList?.getTerminals.map(t => ({
      label: `${capitalize(t.address.state)} -> ${capitalize(t.name.toUpperCase())}`,
      value: t.id,
    })) ?? [];
  const routeItems =
    terminal?.findTerminal.routes.map(r => ({
      label: `${capitalize(r.destination.state ?? '')} -> ${capitalize(r.destination.name)}`,
      value: r.id,
    })) ?? [];

  useEffect(() => {
    clearCache();
  }, []);

  useEffect(() => {
    if (selectedTerminal) {
      findTerminal({ variables: { findTerminalId: selectedTerminal } });
    }
  }, [selectedTerminal]);

  const onPassengerCountChange = (set: PassengerSet) => {
    setAdult(set.adult);
    setChild(set.child);
    setInfant(set.infant);
  };

  const onSearch = async () => {
    const depature: ItenaryInput = {
      adult: String(adult),
      child: String(child),
      infant: String(infant),
      routeId: selectedRoute!,
      terminalId: selectedTerminal!,
      date: moment(departureDate).format('YYYY-MM-DD'),
    };

    const response = await getAvailableShcedules({
      variables: {
        input: {
          depature,
          arrival: arrivalDate ? { ...depature, date: moment(arrivalDate).format('YYYY-MM-DD') } : undefined,
        },
      },
    });

    // clear reservation cache
    await clearCache();

    if (response.data) {
      const t = terminalsList?.getTerminals.find(t => t.id === selectedTerminal);
      const r = terminal?.findTerminal.routes.find(r => r.id === selectedRoute);

      setSearch({
        adult,
        child,
        infant,
        terminal: t?.name!,
        terminalState: t?.address.state!,
        destination: r?.destination.name!,
        destinationState: r?.destination.state!,
        date: moment(departureDate).format('YYYY-MM-DD'),
      });
      setAvailableSchedules(response.data.getAvailableSchedule);
      goToAvailablity();
    }
  };

  const hasPassenger = adult + child + infant > 0;
  const canSubmit = hasPassenger && departureDate && selectedTerminal && selectedRoute;
  const isLoading = loadingResults || loadingTerminal;

  return (
    <ContentContainer showFooter={false} title="Book a seat">
      <SearchableSelect
        label="From"
        formControlStyle={{ width: { base: 'full' }, isRequired: true, zIndex: 2 }}
        placeholder="Select departure"
        isRequired
        items={terminals}
        isLoading={loading}
        isMulti={false}
        onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) =>
          setSelectedTerminal((newValue as SingleValue<SelectItem>)?.value ?? '')
        }
      />

      <SearchableSelect
        label="To"
        formControlStyle={{ width: { base: 'full' }, isRequired: true, zIndex: 1, mt: 4 }}
        placeholder="Select destination"
        items={routeItems}
        isLoading={loadingTerminal}
        onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) =>
          setSelectedRoute((newValue as SingleValue<SelectItem>)?.value ?? '')
        }
      />

      <DateTimePicker
        label="Departing On"
        mode="date"
        mt={4}
        isRequired
        minimumDate={new Date()}
        onDateChange={d => setDepartureDate(d)}
      />
      <PassengerCounter label="Passengers" isRequired mt={4} onComplete={onPassengerCountChange} defaults={{ adult, child, infant }} />
      <View mt="10">
        <Button size="lg" alignSelf="center" width="full" onPress={onSearch} disabled={!canSubmit} isLoading={isLoading}>
          <BodyText text="Search" color="#fff" />
        </Button>
      </View>
    </ContentContainer>
  );
};

export default BookSeatScreen;

import { AspectRatio, Box, Center, ScrollView, Stack, Image } from 'native-base';
import { Pressable } from 'react-native';
import { SubHeader } from 'src/components/typography/SubHeader';

const List = [
  {
    imageOne: 'https://www.localguidesconnect.com/t5/image/serverpage/image-id/746855i4552A48A0311E18B/image-size/large?v=v2&px=999',
    imageText: 'Imo State',
    linkText: 'The Eastern Heartland',
  },
  {
    imageOne: 'https://media.premiumtimesng.com/wp-content/files/2020/05/abuja-1589282102432.jpg',
    imageText: 'Abuja',
    linkText: 'Center of Unity',
  },
  {
    imageOne: 'https://nigerianomad.com/wp-content/uploads/2024/07/enugu_state.jpg',
    imageText: 'Enugu State',
    linkText: 'Coal City',
  },
  {
    imageOne: 'https://www.propertypro.ng/blog/wp-content/uploads/2017/06/010-geography_of-ondostate.jpg',
    imageText: 'Ondo State',
    linkText: 'Sunshine State',
  },
];

export default function FlightScroll() {
  return (
    <Box px={'10%'} py={10} backgroundColor={'white'}>
      <SubHeader text="Top destinations across Nigeria" fontSize={{ base: 15, md: 'xl' }} />
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} p={1} mt={5}>
        <Stack space={5} direction={{ base: 'column', md: 'row' }}>
          {List.map(s => (
            <Pressable key={s.imageText}>
              <Box alignItems="center">
                <Box
                  maxH={240}
                  maxW={{ base: 300, md: 80 }}
                  rounded="lg"
                  overflow="hidden"
                  borderColor="coolGray.200"
                  borderWidth="1"
                  _dark={{
                    borderColor: 'coolGray.600',
                    backgroundColor: 'gray.700',
                  }}
                  _web={{
                    shadow: 2,
                    borderWidth: 0,
                  }}
                  _light={{
                    backgroundColor: 'gray.50',
                  }}
                >
                  <Box>
                    <AspectRatio w="100%" ratio={16 / 9}>
                      <Image
                        source={{
                          uri: s.imageOne,
                        }}
                        alt="image"
                      />
                    </AspectRatio>
                    <Center
                      bg="violet.500"
                      _dark={{
                        bg: 'violet.400',
                      }}
                      _text={{
                        color: 'warmGray.50',
                        fontWeight: '700',
                        fontSize: 'xs',
                      }}
                      position="absolute"
                      bottom="0"
                      px="3"
                      py="1.5"
                    >
                      {s.imageText}
                    </Center>
                  </Box>
                  <Stack p="4" space={3}>
                    <Stack space={2}>
                      <SubHeader text={s.linkText} size="md" ml="-1" />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Pressable>
          ))}
        </Stack>
      </ScrollView>
    </Box>
  );
}

import { Box, HStack, useTheme } from 'native-base';
import { PageHeader } from 'src/components/typography/PageHeader';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { NotificationButton } from './NotificationButton';
import { useLinkProps } from '@react-navigation/native';
import { NOTIFICATION_SCREEN_NAME } from 'src/navigation/constants';

export const Greetings = () => {
  const { colors } = useTheme();
  const { onPress: goToNotifications } = useLinkProps({
    to: { screen: NOTIFICATION_SCREEN_NAME },
  });

  return (
    <Box width="100%" height={171} padding={5} backgroundColor={colors.primary[PRIMARY_COLOR_NUMBER]}>
      <HStack alignContent="center" justifyContent="space-between">
        <PageHeader text="Home" color={colors.white} fontWeight="extrabold" />
        <NotificationButton onPress={goToNotifications} />
      </HStack>
    </Box>
  );
};

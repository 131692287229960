import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useTheme, useToast } from 'native-base';

export const useQueryErrorNotification = <Q = any, V extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<Q, V>,
  options?: QueryHookOptions<Q, V> | undefined
) => {
  const toast = useToast();
  const { colors } = useTheme();

  return useQuery<Q, V>(query, {
    ...options,
    onError: e => {
      e.graphQLErrors.map(e =>
        toast.show({
          title: 'Error completing request',
          description: e.message,
          style: { backgroundColor: colors.danger[100] },
          placement: 'top',
          variant: 'left-accent',
        })
      );
    },
  });
};

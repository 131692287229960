import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RootBottomNavParamList, RootStackParamList } from 'src/types/root.type';
import OnboardingScreen from 'src/screens/onboarding-screen/OnboardingScreen';
import { GuestConfirmation } from 'src/screens/onboarding-screen/GuestScreen';
import HomeScreenPage from 'src/screens/home-page/HomeScreenPage';
import {
  BOOKING_STACK_NAME,
  CHECK_IN_STACK_NAME,
  GUEST_SCREEN_NAME,
  HOME_SCREEN_NAME,
  LANDING_SCREEN_NAME,
  MAIN_SCREEN_NAME,
  MANAGE_BOOKING_STACK_NAME,
  NOTIFICATION_SCREEN_NAME,
  ONBOARDING_SCREEN_NAME,
  RESERVATION_STACK_NAME,
  SETTING_SCREEN_NAME,
} from 'src/navigation/constants';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import ReservationStack from 'src/navigation/ReservationStack';
import { Landing } from 'src/screens/landing/Landing';
import { isWeb } from 'src/utils/environment.utils';
import { TabBar } from 'src/components/bottom-navigation/BottomNavigation';
import { MoreScreen } from 'src/screens/more/MoreScreen';
import { Notifications } from 'src/screens/notifications/Notifications';
import { BookingStack } from './BookingStack';
import { CheckinStack } from './CheckinStack';

const Tab = createBottomTabNavigator<RootBottomNavParamList>();

const TabNavigation = () => {
  return (
    <Tab.Navigator tabBar={TabBar}>
      <Tab.Screen name={HOME_SCREEN_NAME} component={HomeScreenPage} options={{ headerShown: false }} />
      <Tab.Screen name={BOOKING_STACK_NAME} component={BookingStack} options={{ headerShown: false }} />
      <Tab.Screen name={CHECK_IN_STACK_NAME} component={CheckinStack} options={{ headerShown: false }} />
      <Tab.Screen name={SETTING_SCREEN_NAME} component={MoreScreen} options={{ header: NavigationHeader }} />
    </Tab.Navigator>
  );
};

const Stack = createStackNavigator<RootStackParamList>();

const screenOptions: StackNavigationOptions = {
  headerShown: true,
  header: NavigationHeader,
};

export const AppStack = () => {
  const { hasOnboarded } = useAuth();
  const isMobile = !isWeb();
  const initialRouteName = hasOnboarded ? MAIN_SCREEN_NAME : ONBOARDING_SCREEN_NAME;

  return (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName={isMobile ? initialRouteName : LANDING_SCREEN_NAME}>
      {!hasOnboarded && <Stack.Screen name={ONBOARDING_SCREEN_NAME} component={OnboardingScreen} options={{ headerShown: false }} />}

      <Stack.Screen name={LANDING_SCREEN_NAME} component={Landing} options={{ title: 'Home' }} />
      <Stack.Screen name={GUEST_SCREEN_NAME} component={GuestConfirmation} />
      <Stack.Screen name={MAIN_SCREEN_NAME} component={TabNavigation} options={{ headerShown: false }} />
      <Stack.Screen name={RESERVATION_STACK_NAME} component={ReservationStack} options={{ headerShown: false }} />
      <Stack.Screen name={NOTIFICATION_SCREEN_NAME} component={Notifications} />
      {isWeb() && (
        <>
          <Stack.Screen name={MANAGE_BOOKING_STACK_NAME} component={BookingStack} options={{ headerShown: false }} />
          <Stack.Screen name={CHECK_IN_STACK_NAME} component={CheckinStack} options={{ headerShown: false }} />
        </>
      )}
    </Stack.Navigator>
  );
};

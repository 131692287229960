import { Alert as NBAlert, HStack, VStack } from 'native-base';
import { BodyText } from '../typography/BodyText';

export const Alert = ({ description, type = 'info' }: { description: string; type: 'error' | 'success' | 'warning' | 'info' }) => {
  return (
    <NBAlert w="100%" variant={'left-accent'} status={type} marginBottom={4}>
      <VStack space={2} flexShrink={1} w="100%">
        <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <NBAlert.Icon />
            <BodyText color={'#fff'} text={description} />
          </HStack>
        </HStack>
      </VStack>
    </NBAlert>
  );
};

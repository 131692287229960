import React from 'react';

import { Flex, PresenceTransition, Pressable, Slide, View, useTheme } from 'native-base';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { SubHeader } from 'src/components/typography/SubHeader';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { AvailableSchedule, useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { getCurrencyText } from 'src/utils/currency';

export interface BasketFooterProps {
  onContinue: () => void;
  schedule?: AvailableSchedule;
  isArrival?: boolean;
  show?: boolean;
}

export const BasketFooter: React.FC<BasketFooterProps> = ({ onContinue, schedule, isArrival, show = false }) => {
  const { setDepartureSchedule, setArrivalSchedule } = useReservation();
  const { colors } = useTheme();
  const height = { base: '40px', md: '60px' };

  const onContinueClicked = () => {
    if (isArrival && schedule) {
      setArrivalSchedule(schedule);
    } else if (schedule) {
      setDepartureSchedule(schedule);
    }
    onContinue();
  };

  return (
    <PresenceTransition
      style={{ backgroundColor: colors.white, width: '100%' }}
      visible={show}
      initial={{
        opacity: 0,
        scale: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 250,
        },
      }}
    >
      <Flex direction="row" width={'100%'} backgroundColor={colors.white}>
        <Flex borderWidth={1} borderColor={colors.gray[300]} flex={1} height={height} alignItems="center" justifyContent="center">
          <View>
            <SmallLabel text="Total" />
            <SubHeader text={schedule ? getCurrencyText(schedule?.totalFare!) : ''} bold fontSize={{ base: 12, md: 'md' }} />
          </View>
        </Flex>
        <Pressable flex={1} height={height} onPress={onContinueClicked}>
          {({ isPressed, isFocused, isHovered }) => {
            const isDark = isPressed || isFocused || isHovered;
            return (
              <Flex
                backgroundColor={colors.primary[isDark ? 500 : PRIMARY_COLOR_NUMBER]}
                height={height}
                justifyContent="center"
                alignItems="center"
              >
                <SubHeader text="Continue" bold color={colors.white} fontSize={{ base: 12, md: 'md' }} />
              </Flex>
            );
          }}
        </Pressable>
      </Flex>
    </PresenceTransition>
  );
};

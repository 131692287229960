import { LazyQueryResultTuple, QueryResult, gql, useLazyQuery, useQuery } from '@apollo/client';
import { FindTerminalQuery, FindTerminalQueryVariables } from './__generated__/find-terminal.generated';

export type FindTerminalQuery_findTerminal_route = FindTerminalQuery['findTerminal']['routes'][number];

const FIND_TERMINAL_QUERY = gql`
  query FindTerminal($findTerminalId: String!) {
    findTerminal(id: $findTerminalId) {
      id
      name
      routes {
        id
        destination {
          id
          name
          state
          country
        }
      }
    }
  }
`;

export const useFindTerminalQuery = (terminalId: string): QueryResult<FindTerminalQuery, FindTerminalQueryVariables> =>
  useQuery<FindTerminalQuery, FindTerminalQueryVariables>(FIND_TERMINAL_QUERY, { variables: { findTerminalId: terminalId } });

export const useFindTerminalLazyQuery = (): LazyQueryResultTuple<FindTerminalQuery, FindTerminalQueryVariables> =>
  useLazyQuery<FindTerminalQuery, FindTerminalQueryVariables>(FIND_TERMINAL_QUERY);

export const FONTS = {
  REGULAR: 'AvertaStd',
  SEMI_BOLD: 'AvertaStdSemiBold',
  BOLD: 'AvertaStdBold',
};

export const THEME_INPUT_HEIGHT = 12;
export const PRIMARY_COLOR_NUMBER = 400;

export const COLORS = {
  grey: '#F6F7F9',
};

import { ContentContainer } from 'src/components/container/ContentContainer';
import { useLinkProps, useRoute } from '@react-navigation/native';
import {
  HOME_SCREEN_NAME,
  INBOUND_SCREEN_NAME,
  LANDING_SCREEN_NAME,
  PASSENGER_INFORMATION_SCREEN_NAME,
} from 'src/navigation/constants';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { ReservationHeader } from 'src/screens/book-seat/components/ReservationHeader';
import { Button, HStack, Icon, Menu, Pressable } from 'native-base';
import { AvailabilityList } from './components/AvailablilityList';
import { Ionicons } from '@expo/vector-icons';
import { BodyText } from 'src/components/typography/BodyText';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useState } from 'react';
import { sortScheduleBy } from './ReservationUtils';
import { isWeb } from 'src/utils/environment.utils';

const sortOptions = ['Cheapest', 'Earliest', 'Latest'];

export const Availability: React.FC = () => {
  const { onPress: goToPassengerInformation } = useLinkProps({ to: { screen: PASSENGER_INFORMATION_SCREEN_NAME } });
  const { onPress: goToInbound } = useLinkProps({ to: { screen: INBOUND_SCREEN_NAME } });
  const { onPress: goToBookSeat } = useLinkProps({ to: { screen: isWeb() ? LANDING_SCREEN_NAME : HOME_SCREEN_NAME } });
  const [sortType, setSortType] = useState<string>('Earliest');
  const { availableSchedules, loading, search } = useReservation();
  const route = useRoute();

  const isArrival = route.name === INBOUND_SCREEN_NAME;
  const schedules = isArrival ? availableSchedules?.arrivals : availableSchedules?.departures;
  const hasArrivals = !!availableSchedules?.arrivals;
  const sortedSchedules = sortScheduleBy(schedules ?? [], sortType);

  return (
    <ContentContainer
      allWhiteBackground={isWeb() ? false : true}
      showFooter={false}
      header={<ReservationHeader />}
      headerFullWidth={true}
      loading={loading}
    >
      <HStack alignItems="center" justifyContent="space-between" pt={isWeb() ? 10 : 0} pb={10}>
        <HStack space={3} alignItems="center">
          <SubSubHeader text={`${search?.terminalState} to ${search?.destinationState}`} bold />
          <Button size="sm" variant="link" onPress={goToBookSeat}>
            Change
          </Button>
        </HStack>
        <Menu
          w="160"
          closeOnSelect={false}
          trigger={triggerProps => {
            return (
              <Pressable alignItems="center" justifyContent="center" {...triggerProps}>
                <HStack space={2} alignItems="center">
                  <BodyText text={`Sort By ${sortType}`} />
                  <Icon fontWeight="medium" as={Ionicons} size="xs" name="caret-down" />
                </HStack>
              </Pressable>
            );
          }}
        >
          <Menu.OptionGroup defaultValue="Earliest" value={sortType} title="Sort" type="radio">
            {sortOptions.map(so => (
              <Menu.ItemOption value={so} key={so} onPress={() => setSortType(so)}>
                {so}
              </Menu.ItemOption>
            ))}
          </Menu.OptionGroup>
        </Menu>
      </HStack>
      <AvailabilityList schedules={sortedSchedules} onContinue={hasArrivals ? goToInbound : goToPassengerInformation} />
    </ContentContainer>
  );
};

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Divider, Flex, HStack, Icon, Image, Pressable, VStack, View, useTheme } from 'native-base';
import { TripIllustrationImage } from 'src/assets/images/svg/TripIllustration';
import { BodyText } from 'src/components/typography/BodyText';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { getCurrencyText } from 'src/utils/currency';
import { BasketFooter } from './BasketFooter';
import { capitalize } from 'src/utils/string';
import { getVehicleImageByClass } from '../ReservationUtils';
import { AvailableSchedule } from 'src/providers/reservation-provider/ReservationProvider';

export interface AvailabilityItemProps {
  amount: number;
  travelClass: string;
  time: string;
  availableSeats: number;
  onItemSelect: () => void;
  isActive?: boolean;
  onContinue: () => void;
  schedule?: AvailableSchedule;
}

export const AvailabilityListItem: React.FC<AvailabilityItemProps> = ({
  amount,
  travelClass,
  time,
  availableSeats,
  onItemSelect,
  isActive,
  schedule,
  onContinue,
}) => {
  const { colors } = useTheme();
  return (
    <Pressable onPress={onItemSelect}>
      {({ isFocused, isPressed, isHovered }) => {
        const primaryColor = colors.primary[PRIMARY_COLOR_NUMBER];
        const isDark = isFocused || isPressed || isHovered || isActive;
        const borderColor = isDark ? primaryColor : '#e0e0e0';
        return (
          <Box padding={3} borderWidth={1} borderColor={borderColor} borderRadius={3} backgroundColor={colors.white}>
            <HStack space={3}>
              <Image
                alignSelf="center"
                alt="vehicle image"
                source={getVehicleImageByClass(travelClass)}
                resizeMode="contain"
                width={{ base: '50px', sm: '50px', md: '100px' }}
                height={{ base: '50px', sm: '50px', md: '100px' }}
              />
              <VStack flex={1} space={1}>
                <HStack space={3} alignItems="center" marginBottom={2} display={{ base: 'none', sm: 'none', md: 'auto' }}>
                  <VStack alignItems="center">
                    <BodyText text={capitalize(schedule?.route.branch.name!)} bold />
                    <BodyText text={schedule?.route.branch.address.state!} fontSize={8} maxWidth={12} />
                  </VStack>
                  <TripIllustrationImage height={15} width={200} />
                  <VStack alignItems="center">
                    <BodyText text={capitalize(schedule?.route.destination.name!)} bold />
                    <BodyText text={schedule?.route.destination.state!} fontSize={8} maxWidth={12} />
                  </VStack>
                </HStack>
                <HStack space={1} alignItems="center">
                  <Icon as={MaterialCommunityIcons} name="clock-time-four-outline" color={primaryColor} />
                  <BodyText text={time} fontSize={12} bold />
                </HStack>
                <HStack space={1} alignItems="center">
                  <Icon as={MaterialCommunityIcons} name="seat-outline" color={primaryColor} />
                  <BodyText fontSize={12} text={`${availableSeats} Seats available`} />
                </HStack>
                <HStack space={1} alignItems={'center'}>
                  <Icon as={MaterialCommunityIcons} name="information-outline" color={primaryColor} />
                  <BodyText fontSize={12} text="See trip details" color={primaryColor} />
                </HStack>
              </VStack>
              <Divider orientation="vertical" />
              <VStack space={2} width={{ base: 'auto', md: 150 }} padding={2} justifyContent={{ base: 'center', md: 'space-between' }}>
                <SubSubHeader
                  bold
                  text={capitalize(travelClass)}
                  alignSelf={'center'}
                  color={primaryColor}
                  fontSize={{ base: 12, md: 'md' }}
                />
                <VStack>
                  <BodyText text={getCurrencyText(amount)} alignSelf={'center'} bold fontSize={{ base: 12, lg: 18 }} />
                </VStack>
              </VStack>
            </HStack>
            {isActive && (
              <View marginTop={5}>
                <BasketFooter schedule={schedule} onContinue={onContinue} show={isActive} />
              </View>
            )}
          </Box>
        );
      }}
    </Pressable>
  );
};

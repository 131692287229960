import { orderBy } from 'lodash';
import { Flex, View } from 'native-base';
import React from 'react';
import { SelectItem } from 'src/components/select/Select';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { SeatStatusEnum } from 'src/graphql/generated/types';
import { TripSeatMapQuery } from 'src/graphql/ticketing_api/queries/__generated__/get-trip-seatmap.generated';
import { TripSeatMapQuery_tripSeatMap_Seat } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { AvailableSchedule } from 'src/providers/reservation-provider/ReservationProvider';

export enum PassengerTypeEnum {
  Adult = 'Adult',
  Child = 'Child',
  Infant = 'Infant',
}

export const genderOptions: SelectItem[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const SeatColorSheme = Object.freeze({
  [SeatStatusEnum.Available]: '#ecf0f1',
  [SeatStatusEnum.Reserved]: '#95a5a6',
  [SeatStatusEnum.Occupied]: '#27ae60',
  [SeatStatusEnum.Blocked]: '#e74c3c',
  selected: '#2980b9',
});

const titlelist = ['Alh', 'Mr', 'Mrs', 'Miss', 'Dr', 'Prof'];
export const titleOptions: SelectItem[] = titlelist.map<SelectItem>(i => ({ value: i, label: i }));

export const BasketItem: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Flex direction="row" justifyContent="space-between" mt={1}>
      <View flex={1}>
        <SmallLabel text={`${label}:`} />
      </View>
      <View flex={1} textAlign={'right'}>
        <BodyText text={value} />
      </View>
    </Flex>
  );
};

export const getPassengerTypeOrder = (adult: number, child = 0, infant = 0): PassengerTypeEnum[] => {
  const typeList = [...new Array(adult)].map(() => 'Adult') as PassengerTypeEnum[];
  const childList = [...new Array(child)].map(() => 'Child') as PassengerTypeEnum[];
  const infantList = [...new Array(infant)].map(() => 'Infant') as PassengerTypeEnum[];
  return typeList.concat(childList, infantList);
};

export const getSeatStatus = (
  seat: TripSeatMapQuery_tripSeatMap_Seat | undefined,
  selectedIds: number[]
): SeatStatusEnum | 'selected' | undefined => {
  if (!seat) return undefined;

  if (seat.status === SeatStatusEnum.Available && selectedIds.includes(parseInt(seat.id, 10))) {
    return 'selected';
  }
  return seat.status as SeatStatusEnum;
};

export const getSeatMapGrid = (data: TripSeatMapQuery): (TripSeatMapQuery_tripSeatMap_Seat | undefined)[][] => {
  const grid = [];
  for (let i = 1; i <= data.tripSeatMap.length; i++) {
    let row = [];
    for (let j = 1; j <= data.tripSeatMap.width; j++) {
      const seat = data.tripSeatMap.seats.find(s => s.row === i && s.column === j);
      row.push(seat);
    }
    grid.push([...row]);
    row = [];
  }
  return grid;
};

export const getVehicleImageByClass = (className: string) => {
  if (className.toLowerCase() === 'super executive') {
    return require('src/assets/images/toyota-hiace-2021.png');
  } else if (className.toLowerCase() === 'executive') {
    return require('src/assets/images/toyota-hiace-2018.png');
  } else if (className.toLowerCase() === 'luxurious') {
    return require('src/assets/images/Luxurious_bus.png');
  }
};

export const sortScheduleBy = (schedules: AvailableSchedule[], sortKey: string) => {
  if (sortKey === 'Cheapest') {
    return orderBy(schedules, ['totalFare'], ['asc']);
  } else if (sortKey === 'Latest') {
    return orderBy(schedules, ['trip.time'], ['desc']);
  } else {
    return orderBy(schedules, ['trip.time'], ['asc']);
  }
};

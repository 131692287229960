import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { HStack, Pressable, VStack } from 'native-base';
import { Text } from 'react-native';
import {
  BOOKING_STACK_NAME,
  CHECK_IN_SCREEN_NAME,
  CHECK_IN_STACK_NAME,
  HOME_SCREEN_NAME,
  SETTING_SCREEN_NAME,
} from 'src/navigation/constants';
import { RootBottomNavParamList } from 'src/types/root.type';
import Ionicons from '@expo/vector-icons/Ionicons';
import FeatherIcons from '@expo/vector-icons/Feather';
import AntIcons from '@expo/vector-icons/AntDesign';
import { COLORS } from 'src/providers/design-provider/constants';

const getTabIcon = (name: keyof RootBottomNavParamList, color: string) => {
  switch (name) {
    case HOME_SCREEN_NAME:
      return <Ionicons name="bus" size={24} color={color} />;
    case BOOKING_STACK_NAME:
      return <AntIcons name="book" size={24} color={color} />;
    case CHECK_IN_STACK_NAME:
      return <AntIcons name="checkcircleo" size={24} color={color} />;
    case SETTING_SCREEN_NAME:
      return <FeatherIcons name="more-horizontal" size={24} color={color} />;
  }
};

export const TabBar = ({ state, descriptors, navigation }: BottomTabBarProps) => {
  return (
    <HStack
      justifyContent={'space-between'}
      backgroundColor={'#ffffff'}
      paddingTop={5}
      paddingBottom={6}
      borderTopColor={COLORS.grey}
      borderTopWidth={2}
    >
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          (options.tabBarLabel !== undefined ? options.tabBarLabel : options.title !== undefined ? options.title : route.name) ?? '';

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name, route.params);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        return (
          <Pressable
            key={route.key}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={{ flex: 1 }}
          >
            <VStack alignItems="center" justifyContent="space-between" space={2}>
              {getTabIcon(label as keyof RootBottomNavParamList, isFocused ? '#314c94' : '#ced6e0')}
              <Text style={{ color: isFocused ? '#314c94' : '#ced6e0' }}>{`${
                label === CHECK_IN_STACK_NAME ? 'Check-In' : label
              }`}</Text>
            </VStack>
          </Pressable>
        );
      })}
    </HStack>
  );
};

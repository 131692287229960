import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getHasOnBoarded } from 'src/utils/onboarding';

export interface AuthProviderProps {
  children: ReactNode | ReactNode[];
}

export interface AuthContextType {
  hasOnboarded: boolean;
  authenticated: boolean;
}

export const AuthContext = createContext<AuthContextType>({ hasOnboarded: false, authenticated: false });

export const useAuth = () => {
  if (!AuthContext) {
    throw Error('useAuth can not be used outside of an AuthContextProvider.');
  }
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [hasOnboarded, setHasOnBoarded] = useState<boolean>(false);
  const [authenticated] = useState<false>(false);

  useEffect(() => {
    const readValue = async () => {
      const onboarded = await getHasOnBoarded();
      // This should be setHasOnBoarded(onboarded); But for development onboarded should be true
      setHasOnBoarded(onboarded);
    };
    readValue();
  }, [setHasOnBoarded]);

  return <AuthContext.Provider value={{ hasOnboarded, authenticated }}>{children}</AuthContext.Provider>;
};

import { Octicons } from '@expo/vector-icons';
import { Icon, IconButton } from 'native-base';
import { FC } from 'react';

interface NotificationButtonProps {
  onPress: () => void;
}
export const NotificationButton: FC<NotificationButtonProps> = ({ onPress }) => (
  <IconButton
    onPress={onPress}
    icon={<Icon as={Octicons} name="bell" />}
    borderRadius="full"
    _icon={{
      color: 'white',
      size: 'md',
    }}
    _hover={{
      bg: 'orange.600:alpha.20',
    }}
    _pressed={{
      bg: 'orange.600:alpha.20',
      _ios: {
        _icon: {
          size: '2xl',
        },
      },
    }}
    _ios={{
      _icon: {
        size: '2xl',
      },
    }}
  />
);

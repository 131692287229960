import FontistoIcons from '@expo/vector-icons/Fontisto';
import { MaterialCommunityIcons, Entypo, AntDesign } from '@expo/vector-icons';
import { Avatar, HStack, VStack, Box, View, FlatList, Badge, useTheme, Button, ScrollView, Divider } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { LoadingState } from 'src/components/loading-state/LoadingState';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { COLORS, PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { capitalize, getInitials } from 'src/utils/string';
import moment from 'moment';
import { StackActions, useLinkProps } from '@react-navigation/native';
import { BOOKING_ACCESS } from 'src/navigation/constants';

export interface PassengerItemProps {
  fullName: string;
  type: string;
}

export const OriginDestinationIllustration = ({ color, size }: { color: string; size?: number }) => {
  return (
    <HStack space={2} alignItems="center">
      <Entypo name="dots-three-horizontal" size={size} color={color} />
      <MaterialCommunityIcons name="bus" size={size} color={color} />
      <Entypo name="dots-three-horizontal" size={size} color={color} />
    </HStack>
  );
};

const PassengerItem = ({ fullName, type }: PassengerItemProps) => {
  return (
    <Box padding={1}>
      <HStack space={[2, 3]} alignItems="center" justifyContent="space-between">
        <HStack space={[2, 3]} alignItems="center">
          <Avatar size="32px">{getInitials(fullName)}</Avatar>
          <BodyText text={capitalize(fullName)} />
        </HStack>
        <Badge size={'sm'} colorScheme="info" variant="subtle">
          {type}
        </Badge>
      </HStack>
    </Box>
  );
};

export const BookingInformation = () => {
  const { booking, resetBooking } = useBooking();
  const { colors } = useTheme();
  const { onPress: goToBookingAccess } = useLinkProps({
    to: { screen: BOOKING_ACCESS },
    action: StackActions.replace(BOOKING_ACCESS),
  });

  const closeBooking = () => {
    resetBooking();
    goToBookingAccess();
  };

  if (!booking?.getReservationBySurname) return <LoadingState />;

  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      <ScrollView flex={1} contentContainerStyle={{ justifyContent: 'space-between', flex: 1 }} bounces={false}>
        <View>
          <SubSubHeader text="Reference" />
          <SmallLabel text={`# ${booking.getReservationBySurname.id}`} />
          <Box borderColor={COLORS.grey} borderWidth={2} padding={4} borderRadius={2} marginTop={2}>
            <HStack justifyContent="space-evenly" alignItems="center">
              <VStack alignItems="center">
                <SubSubHeader text={booking.getReservationBySurname.schedule.route.branch.name} />
                <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.branch.address.state})`} />
              </VStack>
              <OriginDestinationIllustration color={colors.primary[PRIMARY_COLOR_NUMBER]} size={20} />
              <VStack alignItems="center">
                <SubSubHeader text={booking.getReservationBySurname.schedule.route.destination.name.toUpperCase()} />
                <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.destination.state ?? ''})`} />
              </VStack>
            </HStack>

            <HStack marginTop={5}>
              <VStack space={1}>
                <HStack space={2}>
                  <MaterialCommunityIcons name="bus" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('DD MMM, YYYY')} />
                </HStack>
                <HStack space={2}>
                  <MaterialCommunityIcons name="clock-time-five-outline" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('HH:SS A')} />
                </HStack>
              </VStack>
            </HStack>
          </Box>

          <View marginTop={8}>
            <SubSubHeader text="Passengers" />
            <SmallLabel text="Ticketed passengers" />
            <Box borderColor={COLORS.grey} borderWidth={2} borderRadius={2} padding={4} marginTop={2}>
              <FlatList
                scrollEnabled={false}
                data={booking.getReservationBySurname.tickets}
                ItemSeparatorComponent={() => <Divider backgroundColor={'gray.100'} margin={1} />}
                renderItem={({ item }) => <PassengerItem fullName={item.passenger.fullName} type={item.passenger.type} />}
                keyExtractor={item => item.passenger.id}
              />
            </Box>
          </View>

          <View marginTop={8}>
            <SubSubHeader text="Contact Information" />
            <SmallLabel text="For E-Ticket/voucher" />
            <Box borderColor={COLORS.grey} borderWidth={2} borderRadius={2} padding={4} marginTop={2}>
              <VStack>
                <HStack space={2} alignItems={'center'}>
                  <Avatar size={'sm'} shadow={0}>
                    {getInitials(booking.getReservationBySurname.contactName)}
                  </Avatar>
                  <BodyText text={capitalize(booking.getReservationBySurname.contactName)} />
                </HStack>

                <Box borderColor={COLORS.grey} borderWidth={2} padding={2} marginTop={2}>
                  <VStack space={2}>
                    <HStack space={2}>
                      <FontistoIcons name="email" size={20} />
                      {booking.getReservationBySurname?.contactEmail ? (
                        <BodyText text={booking.getReservationBySurname?.contactEmail ?? 'Not available'} />
                      ) : (
                        <SmallLabel text="Not available" />
                      )}
                    </HStack>
                    <HStack space={2}>
                      <FontistoIcons name="phone" size={20} />
                      <BodyText text={booking.getReservationBySurname.contactPhone} />
                    </HStack>
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </View>
        </View>
        <Button onPress={closeBooking} startIcon={<AntDesign name="close" />} marginBottom={2} variant="ghost" colorScheme="secondary">
          Close
        </Button>
      </ScrollView>
    </ContentContainer>
  );
};

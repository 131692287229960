import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { CreatePassengerInput } from 'src/graphql/ticketing_api/mutations/create-reservation';
import { GetAvailableScheduleQuery } from 'src/graphql/ticketing_api/queries/__generated__/get-availability.generated';
import { PriceBooking } from 'src/graphql/ticketing_api/mutations/price-booking';
import { CreateReservationMutation } from 'src/graphql/ticketing_api/mutations/__generated__/create-reservation.generated';
import { CompleteBooking } from 'src/graphql/ticketing_api/mutations/complete-booking';
import { getItem, removeItem, saveItem } from 'src/utils/storage/store';
import { StorageKeysEnum } from 'src/constants/storage';

export function emptyDefaultFunction<T = any, F = any>(arg: T, ...args: F[]): void {}

export interface ReservationProviderProps {
  children: ReactNode | ReactNode[];
}

export interface AvailablitySearch {
  date: string;
  terminalState: string;
  terminal: string;
  destination: string;
  destinationState: string;
  adult: number;
  child: number;
  infant: number;
}

export type GetAvailableSchedule = GetAvailableScheduleQuery['getAvailableSchedule'];
export type AvailableSchedule = GetAvailableScheduleQuery['getAvailableSchedule']['departures'][number];

export interface ReservationContextType {
  availableSchedules?: GetAvailableSchedule | undefined;
  departureSchedule?: AvailableSchedule | undefined;
  arrivalSchedule?: AvailableSchedule | undefined;
  passengers?: CreatePassengerInput[] | undefined;
  search?: AvailablitySearch | undefined;
  bookingPrice?: PriceBooking | undefined;
  completeBooking?: CompleteBooking | undefined;
  loading?: boolean | undefined;
  reservation?: CreateReservationMutation | undefined;
  editablePassengerIndex?: number;
  setEditablePassengerIndex: (index?: number) => void;
  setReservation: (reservation: CreateReservationMutation) => void;
  addPassenger?: (passengers: CreatePassengerInput) => void;
  updatePassenger: (index: number, passengers: CreatePassengerInput) => void;
  setAvailableSchedules: (available: GetAvailableSchedule) => void;
  setDepartureSchedule: (schedule: AvailableSchedule) => void;
  setCompleteBooking: (booking: CompleteBooking) => void;
  setArrivalSchedule: (schedule: AvailableSchedule) => void;
  setSearch: (search: AvailablitySearch) => void;
  setBookingPrice: (bookingPrice: PriceBooking) => void;
  clearCache: () => Promise<void>;
}

export type ReservationCache = Partial<
  Pick<
    ReservationContextType,
    | 'passengers'
    | 'reservation'
    | 'search'
    | 'completeBooking'
    | 'bookingPrice'
    | 'availableSchedules'
    | 'departureSchedule'
    | 'arrivalSchedule'
    | 'editablePassengerIndex'
  >
>;

export const ReservationContext = createContext<ReservationContextType>({
  setAvailableSchedules: emptyDefaultFunction<GetAvailableSchedule>,
  setDepartureSchedule: emptyDefaultFunction<AvailableSchedule>,
  setArrivalSchedule: emptyDefaultFunction<AvailableSchedule>,
  setSearch: emptyDefaultFunction<AvailablitySearch>,
  addPassenger: emptyDefaultFunction<CreatePassengerInput>,
  updatePassenger: (index: number, input: CreatePassengerInput) => {},
  setBookingPrice: emptyDefaultFunction<PriceBooking>,
  setReservation: emptyDefaultFunction<CreateReservationMutation>,
  setCompleteBooking: emptyDefaultFunction<CompleteBooking>,
  setEditablePassengerIndex: emptyDefaultFunction<number | undefined>,
  clearCache: async () => {},
});

export const useReservation = () => {
  if (!ReservationContext) {
    throw Error('useReservation can not be used outside of an ReservationContextProvider.');
  }
  return useContext(ReservationContext);
};

export const ReservationProvider = ({ children }: ReservationProviderProps) => {
  const [loadingCache, setLoadingCache] = useState<boolean>(true);
  const [editablePassengerIndex, setEditablePassengerIndex] = useState<number>();
  const [reservation, setReservation] = useState<CreateReservationMutation>();
  const [search, setSearch] = useState<AvailablitySearch>();
  const [completeBooking, setCompleteBooking] = useState<CompleteBooking>();
  const [passengers, setPassengers] = useState<CreatePassengerInput[]>([]);
  const [bookingPrice, setBookingPrice] = useState<PriceBooking>();
  const [availableSchedules, setAvailableSchedules] = useState<GetAvailableSchedule>();
  const [departureSchedule, setDepartureSchedule] = useState<AvailableSchedule>();
  const [arrivalSchedule, setArrivalSchedule] = useState<AvailableSchedule>();

  useEffect(() => {
    const restoreCache = async () => {
      const cache = await getItem<ReservationCache>(StorageKeysEnum.RESERVATION);
      if (cache) {
        setArrivalSchedule(cache.arrivalSchedule);
        setBookingPrice(cache.bookingPrice);
        setCompleteBooking(cache.completeBooking);
        setSearch(cache.search);
        setPassengers(cache.passengers ?? []);
        setAvailableSchedules(cache.availableSchedules);
        setDepartureSchedule(cache.departureSchedule);
        setReservation(cache.reservation);
        setEditablePassengerIndex(cache.editablePassengerIndex);
      }
      setLoadingCache(false);
    };
    restoreCache();
  }, []);

  useEffect(() => {
    saveItem(StorageKeysEnum.RESERVATION, {
      search,
      completeBooking,
      passengers,
      bookingPrice,
      availableSchedules,
      departureSchedule,
      arrivalSchedule,
      reservation,
      editablePassengerIndex,
    });
  }, [
    search,
    completeBooking,
    passengers,
    bookingPrice,
    availableSchedules,
    departureSchedule,
    arrivalSchedule,
    reservation,
    editablePassengerIndex,
  ]);

  const addPassenger = useCallback(
    (p: CreatePassengerInput) => {
      if (passengers) {
        const travelScheduleId = departureSchedule?.id;
        const tripId = departureSchedule?.trip.id;
        const branchId = departureSchedule?.branchId;
        const routeId = departureSchedule?.routeId;
        const newList = [...passengers];
        newList.push({
          ...p,
          isPrimaryPassenger: passengers.length === 0,
          travelScheduleId: Number(travelScheduleId),
          tripId: Number(tripId),
          branchId: Number(branchId),
          routeId: Number(routeId),
        });
        setPassengers(newList);
      }
    },
    [setPassengers, passengers, departureSchedule]
  );

  const updatePassenger = useCallback(
    (index: number, p: CreatePassengerInput) => {
      if (passengers) {
        const newList = [...passengers];
        newList[index] = p;
        setPassengers(newList);
      }
    },
    [setPassengers, passengers]
  );

  const clearCache = useCallback(async () => {
    await removeItem(StorageKeysEnum.RESERVATION);
    setReservation(undefined);
    setCompleteBooking(undefined);
    setAvailableSchedules(undefined);
    setDepartureSchedule(undefined);
    setBookingPrice(undefined);
    setArrivalSchedule(undefined);
    setSearch(undefined);
    setPassengers([]);
    setEditablePassengerIndex(undefined);
  }, [
    setReservation,
    setCompleteBooking,
    setAvailableSchedules,
    setBookingPrice,
    setArrivalSchedule,
    setPassengers,
    setSearch,
    setEditablePassengerIndex,
  ]);

  if (loadingCache) return null;

  return (
    <ReservationContext.Provider
      value={{
        availableSchedules,
        departureSchedule,
        arrivalSchedule,
        search,
        completeBooking,
        bookingPrice,
        passengers,
        addPassenger,
        updatePassenger,
        setReservation,
        setCompleteBooking,
        reservation,
        setAvailableSchedules,
        setDepartureSchedule,
        setBookingPrice,
        setArrivalSchedule,
        setEditablePassengerIndex,
        editablePassengerIndex,
        setSearch,
        clearCache,
        loading: false,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

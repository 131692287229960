import { COLORS, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';

export const selectComponentTheme = {
  baseStyle: {
    height: THEME_INPUT_HEIGHT,
    borderRadius: 4,
    borderWidth: 0,
    bg: COLORS.grey,
    customDropdownIconProps: {
      mr: 4,
      size: 4,
    },
  },
};
